@media only screen and (min-width:955px) {
    .hulu-banner-text-focused {
        font-size: 70px;
    }

    .hulu-slider {
        height: 25vw;
    }
}

@media only screen and (min-width:768px) {
    .mainHeader .mainLogoContainer {
        -ms-flex-align: left;
        align-items: left;
        justify-content: left !important;
    }

    .menuItem,
    .header-item {
        display: flex !important;
    }

    .menuItem.mob-only,
    .header-item.mob-only {
        display: none !important;
    }

    .hulu-banner-text-focused {
        font-size: calc(48px + 1vw);
    }
}

@media only screen and (min-width:576px) {}

@media only screen and (min-width:480px) {
    .hulu-banner-text-focused {
        /* font-size: 28px; */
    }
}

@media only screen and (max-width:479px) {
    :root {
        --mainheader-height: 60px !important;
        --extendedHeader-height: 26px !important;
        --banner-height: 20vh !important;
        --banner-max-height: 180px !important;
        --banner-min-height: 100px !important;
    }

    #root .mainHeader {
        height: var(--mainheader-height) !important;
    }

    #root .extendedHeader {
        padding: 0 !important;
        margin-top: var(--mainheader-height) !important;
        line-height: var(--extendedHeader-height) !important;
        min-height: var(--extendedHeader-height) !important;
        height: var(--extendedHeader-height) !important;
    }

    .extendedHeader .left-nav .menuItem {
        font-size: calc(8px + 1vw) !important;
        /* font-size: 0px!important; */
        margin: auto 10px auto 0 !important;
    }

    .extendedHeader .left-nav .menuItem i {
        font-size: calc(12px + 1vw) !important;
        margin: auto !important;
    }

    .extendedHeader .left-nav .menuItem.active {
        font-size: 12px !important;
    }

    .extendedHeader .left-nav .menuItem.active i,
    .extendedHeader .right-nav .menuItem.menuItemButton i {
        margin-right: 5px !important;
    }

    .extendedHeader .right-nav .menuItem {
        margin: auto 0 auto 4px !important;
    }

    .extendedHeader .menuItem-time {
        display: none !important;
    }

    .extendedHeader .menuItem,
    .mainHeader .right-nav {
        padding: 0 5px !important;
        font-size: 12px !important;
        font-weight: lighter !important
    }

    .header-link-text {
        height: unset !important;
        min-height: unset !important;
        max-height: unset !important;
        line-height: unset;
        min-width: unset !important
    }

    .header-item,
    .menuItem {
        display: none !important
    }

    .header-item.mob-only,
    .header-item.show-any,
    .menuItem.mob-only,
    .menuItem.show-any {
        display: flex !important
    }

    .hulu-banner-text-focused {
        font-weight: 700;
        font-size: calc(24px + 1.5vw)
    }
}





/* 

 @media only screen and (max-width:1075px) {
    .sliderItem {
        background-size: contain;
        margin-top: 112px;
        height: 260px;

    } 

   
  .slider1E {
    background-image: url("../assets/img/slider/web/promo1WE.jpg");
  }
  
  .slider1A {
    background-image: url("../assets/img/slider/web/promo1WA.jpg");
  }
  
  .slider2E {
    background-image: url("../assets/img/slider/web/promo2WE.jpg");
  }
  
  .slider2A {
    background-image: url("../assets/img/slider/web/promo2WA.jpg");
  }
  
  .slider3E {
    background-image: url("../assets/img/slider/web/promo3WE.jpg");
  }
  
  .slider3A {
    background-image: url("../assets/img/slider/web/promo3WA.jpg");
  } 

 }  */

@media (max-width:375px) {
    .sliderItem {
        background-size: contain;
        margin-top: 75px;
        height: 290px;
    }

    .slider1E {
        background-image: url("../assets/img/slider/mobile/promo1ME.jpg");
    }

    .slider1A {
        background-image: url("../assets/img/slider/mobile/promo1MA.jpg");
    }

    .slider2E {
        background-image: url("../assets/img/slider/mobile/promo2ME.jpg");
    }

    .slider2A {
        background-image: url("../assets/img/slider/mobile/promo2MA.jpg");
    }

    .slider3E {
        background-image: url("../assets/img/slider/mobile/promo3ME.jpg");
    }

    .slider3A {
        background-image: url("../assets/img/slider/mobile/promo3MA.jpg");
    }
    .jackpot1A {
        background-image: url("../assets/img/slider/mobile/jackpot1MA.jpg");
    }
    .jackpot1E {
        background-image: url("../assets/img/slider/mobile/jackpot1ME.jpg");
    }
    .jackpot2A {
        background-image: url("../assets/img/slider/mobile/jackpot2MA.jpg");
    }
    .jackpot2E {
        background-image: url("../assets/img/slider/mobile/jackpot2ME.jpg");
    }
    .jackpotEmpty {
        background-image: url("../assets/img/slider/mobile/Jackpot3M.jpg");
    }
    .jackpotContent{
        color: white;
        font-size:20px !important;
        margin-top:0px !important;
        margin-left:10px !important;

        /* display:"flex" !important; */
        /* justify-content:"flex-start" !important; */
        /* justify-items:"center" !important; */
        align-items: center;
    }
}

@media (min-width:375px) and (max-width:540px) {
    .sliderItem {
        background-size: contain;
        margin-top: 43px;
        height: 290px;
    }

    .slider1E {
        background-image: url("../assets/img/slider/mobile/promo1ME.jpg");
    }

    .slider1A {
        background-image: url("../assets/img/slider/mobile/promo1MA.jpg");
    }

    .slider2E {
        background-image: url("../assets/img/slider/mobile/promo2ME.jpg");
    }

    .slider2A {
        background-image: url("../assets/img/slider/mobile/promo2MA.jpg");
    }

    .slider3E {
        background-image: url("../assets/img/slider/mobile/promo3ME.jpg");
    }

    .slider3A {
        background-image: url("../assets/img/slider/mobile/promo3MA.jpg");
    }
    .jackpot1A {
        background-image: url("../assets/img/slider/mobile/jackpot1MA.jpg");
    }
    .jackpot1E {
        background-image: url("../assets/img/slider/mobile/jackpot1ME.jpg");
    }
    .jackpot2A {
        background-image: url("../assets/img/slider/mobile/jackpot2MA.jpg");
    }
    .jackpot2E {
        background-image: url("../assets/img/slider/mobile/jackpot2ME.jpg");
    }
    .jackpotEmpty {
        background-image: url("../assets/img/slider/mobile/Jackpot3M.jpg");
    }
    .jackpotContent{
        color: white;
        font-size:20px !important;
        margin-top:0px !important;
        margin-left:10px !important;
        /* display:"flex" !important; */
        /* justify-content:"flex-start" !important; */
        /* justify-items:"center" !important; */
        align-items: center;
    }
}

@media (min-width:540px) and (max-width:680px) {
    .sliderItem {
        background-size: cover;
        margin-top: 50px;
        height: 280px;
    }

    .slider1E {
        background-image: url("../assets/img/slider/mobile/promo1ME.jpg");
    }

    .slider1A {
        background-image: url("../assets/img/slider/mobile/promo1MA.jpg");
    }

    .slider2E {
        background-image: url("../assets/img/slider/mobile/promo2ME.jpg");
    }

    .slider2A {
        background-image: url("../assets/img/slider/mobile/promo2MA.jpg");
    }

    .slider3E {
        background-image: url("../assets/img/slider/mobile/promo3ME.jpg");
    }

    .slider3A {
        background-image: url("../assets/img/slider/mobile/promo3MA.jpg");
    }
    .jackpot1A {
        background-image: url("../assets/img/slider/mobile/jackpot1MA.jpg");
    }
    .jackpot1E {
        background-image: url("../assets/img/slider/mobile/jackpot1ME.jpg");
    }
    .jackpot2A {
        background-image: url("../assets/img/slider/mobile/jackpot2MA.jpg");
    }
    .jackpot2E {
        background-image: url("../assets/img/slider/mobile/jackpot2ME.jpg");
    }
    .jackpotEmpty {
        background-image: url("../assets/img/slider/mobile/Jackpot3M.jpg");
    }
    .jackpotContent{
        color: white;
        font-size:20px !important;
        margin-top:0px !important;
        margin-left:10px !important;
        /* display:"flex" !important; */
        /* justify-content:"flex-start" !important; */
        /* justify-items:"center" !important; */
        align-items: center;
    }
}


@media (min-width:680px) and (max-width:970px) {
    .sliderItem {
        background-size: cover;
        margin-top: 15px;
        height: 325px;
    }

    .slider1E {
        background-image: url("../assets/img/slider/mobile/promo1ME.jpg");
    }

    .slider1A {
        background-image: url("../assets/img/slider/mobile/promo1MA.jpg");
    }

    .slider2E {
        background-image: url("../assets/img/slider/mobile/promo2ME.jpg");
    }

    .slider2A {
        background-image: url("../assets/img/slider/mobile/promo2MA.jpg");
    }

    .slider3E {
        background-image: url("../assets/img/slider/mobile/promo3ME.jpg");
    }

    .slider3A {
        background-image: url("../assets/img/slider/mobile/promo3MA.jpg");
    }
    .jackpot1A {
        background-image: url("../assets/img/slider/mobile/jackpot1MA.jpg");
    }
    .jackpot1E {
        background-image: url("../assets/img/slider/mobile/jackpot1ME.jpg");
    }
    .jackpot2A {
        background-image: url("../assets/img/slider/mobile/jackpot2MA.jpg");
    }
    .jackpot2E {
        background-image: url("../assets/img/slider/mobile/jackpot2ME.jpg");
    }
    .jackpotEmpty {
        background-image: url("../assets/img/slider/mobile/Jackpot3M.jpg");
    }
    .jackpotContent{
        color: white;
        font-size:40px !important;
        margin-top: 0px  !important;
        margin-left:10px !important;
        /* display:"flex" !important; */
        /* justify-content:"flex-start" !important; */
        /* justify-items:"center" !important; */
        align-items: center;
    }
}

@media (min-width:971px) and (max-width:1255px) {
    .sliderItem {
        background-size: cover;
        margin-top: 112px;
        height: 140px;
    }
}

@media (min-width:1255px) and (max-width:1300px) {
    .sliderItem {
        background-size: cover;
        margin-top: 112px;
        height: 180px;
    }
}

@media (min-width:1300px) and (max-width:1441px) {
    .sliderItem {
        background-size: contain;
        margin-top: 112px;
        height: 180px;
    }
}

@media only screen and (min-width:1441px) and (max-width:2230px) {
    .sliderItem {
        background-size: cover;
        margin-top: 112px;
        height: 260px;
    }
}

@media only screen and (min-width:2230px) {
    .sliderItem {
        background-size: contain;
        margin-top: 120px;
        height: 320px;
        /* background-size: cover;
        margin-top: 85px;
        height: 360px; */
    }
}