:root {
  --cp-1: #4cae4e;
  --cp1-rgb: 76, 174, 78;
  --cp-2: #2196f3;
  --cp2-rgb: 33, 150, 243;
  --cp-3: #212121;
  --cp3-rgb: 33, 33, 33;
  --cp-4: #263238;
  --cp4-rgb: 38, 50, 56;
  --cp-1a: #4cae4e;
  --cp-1b: #6caf6d;
  --cp-1c: #99b699;
  --cp-2a: #2196f3;
  --cp-2b: #00838f;
  --cp-2c: #004d40;
  --cp-3a: #212121;
  --cp-3b: #424242;
  --cp-3c: #757575;
  --cp-4a: #263238;
  --cp-4b: #455a64;
  --cp-4c: #37474f;
  --cp-focus: #e7dc3e;
  --mainheader-height: 80px;
  --extendedHeader-height: 30px;
  --logo-height: 30%;
  --banner-height: 35vh;
  --banner-max-height: 200px;
  --banner-min-height: 150px;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 986px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
}

h3 {
  font-size: 1.53125rem !important;
}

.h1,
.h2,
.h3,
.h4,
.h5,
.h6,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0.5rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f3f5;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

.default-color {
  background-color: #4cae4e !important;
}

.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #fff;
  transition: 0.35s;
}

.sliderText {
  display: inline-block;
  width: auto;
  font-weight: 599;
  font-size: 60px;
  color: #fff;
  line-height: 38px;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.95);
}

.center {
  padding: 200px 0;
  text-align: center;
}

.center2 {
  padding: 100px 0;
  text-align: center;
}

.sliderItemContentSpace {
  font-size: 18px;
}

.sliderItem {
  display: flex;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 185px;
  /* height: 180px; */
}

.slider1E {
  background-image: url("../assets/img/slider/web/promo1WE.jpg");
}

.slider1A {
  background-image: url("../assets/img/slider/web/promo1WA.jpg");
}

.slider2E {
  background-image: url("../assets/img/slider/web/promo2WE.jpg");
}

.slider2A {
  background-image: url("../assets/img/slider/web/promo2WA.jpg");
}

.slider3E {
  background-image: url("../assets/img/slider/web/promo3WE.jpg");
}

.slider3A {
  background-image: url("../assets/img/slider/web/promo3WA.jpg");
}

/* Jackpot slider */
.jackpot1E {
  background-image: url("../assets/img/slider/web/jackpot1WE.jpg");
}

.jackpot1A {
  background-image: url("../assets/img/slider/web/jackpot1WA.jpg");
}


.jackpot2E {
  background-image: url("../assets/img/slider/web/jackpot2WE.jpg");
}

.jackpot2A {
  background-image: url("../assets/img/slider/web/jackpot2WA.jpg");
}

.jackpotEmpty {
  background-image: url("../assets/img/slider/web/Jackpot3W.jpg");
}

.jackpotContent{
  display:"flex";
  justify-items: center;
  /* justify-content:"flex-start"; */
  color:white;
  font-weight: "bold";
  font-size:35px;
  margin-top:40px;
  margin-left:100px; 
}
/* 
.jackpot3E {
  background-image: url("../assets/img/slider/web/promo3WE.jpg");
}

.jackpot3A {
  background-image: url("../assets/img/slider/web/promo3WA.jpg");
} */

#root {
  background-color: #263238;
}

.noPad>div {
  padding: 10px !important;
}

.noPad2>div {
  padding: 5px 10px !important;
}

.noPad3>div,
.noPad4>div.ant-modal-content>div.ant-modal-body {
  padding: 0 !important;
}

.noPad5>div.ant-modal-content>div.ant-modal-body {
  padding: 5px !important;
}

.higlightGreen {
  color: #fff;
  padding: 5px;
  cursor: pointer;
  background-color: #424242;
}

.higlightGreen:hover {
  background-color: #4cae4e;
  cursor: pointer;
}

.higlightGray :hover {
  background-color: #424242 !important;
  cursor: pointer;
}

.table-row-dark,
.table-row-light {
  background-color: #455a64;
  color: #fff;
}

.ant-table-small {
  border: 0 solid #e8e8e8 !important;
}

.ant-table-header-column {
  width: 100%;
}

.ant-table-thead {
  background-color: #37474f;
  color: #468df4;
}

.tableitem :hover {
  background-color: #4cae4e !important;
  cursor: pointer;
}
.jackpot-history-item :hover {
  background-color: #4cae4e !important;
  cursor: pointer;
}

.tableitemPrice {
  float: right;
  margin-right: 5px;
  color: #4cae4e;
}

.tableitem:hover .tableitemPrice,
.tableitemPrice :hover {
  color: #fff;
}

.active>center>strong {
  color: #fff !important;
  -webkit-text-decoration: #fff !important;
  text-decoration: #fff !important;
}

.active {
  border-bottom: 3px solid #4cae4e;
}

.active,
.menuItem:hover {
  background-color: rgba(33, 33, 33, 0.3) !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  height: 100%;
}

.menuItem2,
.menuItem:hover {
  border-bottom: 3px solid #fff !important;
}

.menuItem2 {
  background-color: #4cae4e !important;
  height: 100%;
}

.activeI {
  color: #4cae4e !important;
  color: #e7dc3e !important;
  color: var(--cp-focus) !important;
  text-shadow: 2px 8px 6px rgba(38, 50, 56, 0.5),
    0 -5px 35px rgba(33, 33, 33, 0.2) !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0 -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.dropdown-default {
  right: 0 !important;
}

.dropdown-default2 {
  right: 0 !important;
  position: absolute;
  min-width: 7em !important;
}

.ant-radio-button-wrapper {
  width: 20%;
  background-color: #455a64 !important;
  color: #fff !important;
  border-radius: 3px;
  margin: 5px;
  border: 1px solid #37474f !important;
}

.ant-radio-button-wrapper-checked {
  border-color: #4cae4e !important;
  box-shadow: -1px 0 0 0 #4cae4e !important;
  color: #4cae4e !important;
}

.ant-radio-button-wrapper-disabled {
  background-color: #263238 !important;
}

.ant-table-row,
tr {
  border: 2px solid #37474f !important;
}

td {
  border-top: 2px solid #37474f !important;
  border-bottom: 2px solid #37474f !important;
}

.ant-table-tbody>tr.ant-table-row-level-1:hover>td,
.ant-table-tbody>tr:hover,
.ant-table-tbody>tr:hover>td {
  background: #455a64 !important;
}

.breadcrumbico,
.mobTable {
  display: none !important;
}

.middleView {
  max-height: 1100 !important;
}

.middleView::-webkit-scrollbar {
  width: 0px;
  height:0px !important;
}

.middleViewScroll {
  max-height: 760px !important;
  overflow: scroll !important;
}
  @media (max-width: 986px) {
    .middleViewScroll {
      max-height: 500px !important;
      /* overflow: scroll !important; */
    }
  }


.middleViewScroll::-webkit-scrollbar {
  width: 0px;
  height:0px !important;
}

.fabb {
  display: none !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}

.ant-drawer-body {
  padding: 0 !important;
}

.mainLogo {
  height: 80px;
}

.LoginButton {
  padding-left: 15px;
  padding-right: 15px;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3) !important;
}

.ant-input-prefix>input,
.LoginButton {
  border-radius: 0 !important;
}

.mainLogoContainer {
  padding-left: 10px !important;
}

.betHistoryItem {
  cursor: pointer;
}

.betHistoryItem:hover {
  cursor: pointer;
  background-color: rgba(84, 110, 122, 0.5019607843137255) !important;
}

.headerLink {
  color: #fff;
  margin-bottom: 3px;
  font-family: Verdana, Geneva, Tahoma, sans-serif !important;
  text-shadow: 2px 8px 6px rgba(38, 50, 56, 0.5),
    0 -5px 35px rgba(33, 33, 33, 0.2) !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0 -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.pageNavigation {
  color: #fff;
  vertical-align: middle !important;
  cursor: pointer;
}

.pageNavigation:hover {
  color: #4cae4e !important;
}

@media (max-width: 986px) {
  .middleView {
    height: 700 !important;
  }

  .mobileTimeFilter{
    background-color:"#37474f";
    margin-top:"10px";
    margin-bottom:"5px";
  }
  .mobTable {
    display: inline !important;
    display: initial !important;
  }

  .fullTable {
    display: none !important;
  }

  .sliderText {
    font-size: 20px;
  }

  .headerRight {
    display: none !important;
  }

  .breadcrumbico {
    display: block !important;
  }

  .floatRightimg {
    float: right !important;
  }

  .fabb {
    display: inline !important;
    display: initial !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.8), 0 4px 15px 0 rgba(0, 0, 0, 0.8);
  }

  .fixHeight {
    height: 47px;
  }

  .mainLogo {
    height: 55px;
  }

  .mainLogoContainer {
    padding-left: 0 !important;
  }
}

@-webkit-keyframes bgscroll {
  0% {
    background-position: 0 300px;
  }

  to {
    background-position: 0 -270px;
  }
}

.ani {
  background: url(../assets/img/gslide.png);
  background-repeat: no-repeat;
  background-size: 100%;
  height: 300px;
  color: #ecf2f9;
  text-align: center;
  -webkit-animation: bgscroll 7s linear infinite;
}

.scrollImgContainer {
  margin: 0 auto;
  position: relative;
  padding: 0;
}

@media (max-width: 560px) {
  .middleView {
    height: 700 !important;
  }

  .mobTable {
    display: inline !important;
    display: initial !important;
  }

  .fullTable {
    display: none !important;
  }

  .sliderText {
    font-size: 20px;
  }

  .headerRight {
    display: none !important;
  }

  .breadcrumbico {
    display: block !important;
  }

  .floatRightimg {
    float: right !important;
  }

  .fabb {
    display: inline !important;
    display: initial !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.8), 0 4px 15px 0 rgba(0, 0, 0, 0.8);
  }

  .fixHeight {
    height: 47px;
  }

  .mainLogo {
    height: 55px;
  }

  .mainLogoContainer {
    padding-left: 0 !important;
  }

  .ani {
    height: 150px !important;
  }

  .center {
    padding: 60px 0;
    text-align: center;
  }

  .center2 {
    padding: 30px 0;
    text-align: center;
  }

  /* .sliderItem {
    margin-top: 70px;
    height: 260px;
  } */

  .sliderItemContent {
    margin-top: 10px;
    font-size: 16px !important;
  }

  .sliderItemContentSpace {
    margin-top: 0px !important;
    padding: 0px !important;
    font-size: 10px !important;
  }

  .ant-carousel {
    height: 260px;
  }
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-primary {
  color: #fff !important;
  background-color: #20a8d8 !important;
  border-color: #20a8d8 !important;
}

.btn-success {
  color: #fff !important;
  background-color: #4dbd74 !important;
  border-color: #4dbd74 !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #23282c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-collapse-borderless>.ant-collapse-item,
.ant-collapse-item ant-collapse-no-arrow {
  border-bottom: 1px solid grey !important;
}

.ant-tabs-tab {
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #fff !important;
  color: #fff !important;
  background-color: #37474f !important;
}

.ant-tabs-bar {
  margin: 0;
}

.ant-tabs-tab-active {
  color: #fff !important;
  background-color: #4cae4e !important;
  border-radius: 0 !important;
  border: 0 !important;
  border-bottom: 1px solid #fff !important;
}

.ant-input-search-icon {
  color: #4cae4e !important;
}

.ant-input-search>input {
  color: grey !important;
  background-color: white !important;
  border: 0;
  border-bottom: 0.1px solid #fff;
  border-radius: 0;
}

.ant-collapse-content-box {
  background: transparent !important;
  padding: 0 !important;
}

.ant-collapse-header {
  padding: 0px 0px !important;
}

.ant-row.sidebarCollapsable {
  padding:  7px 16px !important;
}

.sidebarCollapsableActive:hover {
  color:#4cae4e !important;
  background-color:rgb(41, 58, 66) !important;
} 

.ant-drawer-wrapper-body {
  background-color: #000000d9 !important;
}

.ant-drawer-left>.ant-drawer-content-wrapper>.ant-drawer-content>.ant-drawer-wrapper-body {
  background-color: #fff !important;
}

.extendedHeader {
  color: #fff !important;
  background-color: #424242 !important;
  background-color: var(--cp-3b) !important;
  padding: 3px 10px !important;
  position: fixed !important;
  line-height: 30px !important;
  line-height: var(--extendedHeader-height) !important;
  width: 100% !important;
  z-index: 1 !important;
}

a.header-link {
  padding-right: 10px !important;
  padding-left: 10px !important;
  height: inherit !important;
  display: inline-block !important;
}

.headerForm {
  margin: auto 0 !important;
}

.header-link-text,
.headerForm {
  display: flex;
  justify-content: flex-end;
}

.header-link-text {
  height: 24px !important;
  min-height: 24px !important;
  cursor: pointer;
}

.header-link-text .link-text {
  color: #fff;
  margin-bottom: 3px;
  font-family: Verdana, Geneva, Tahoma, sans-serif;
}

.input-holder {
  max-width: 30% !important;
  min-width: 20% !important;
  margin: 0 0 0 10px !important;
}

.button-holder {
  margin: 0 0 0 10px !important;
  min-height: 100% !important;
  bottom: 0 !important;
}

.headerForm .ant-btn.login-btn,
.headerForm .ant-input {
  height: 24px !important;
  min-height: 24px !important;
  top: 0;
  color: #263238;
  color: var(--cp-4);
  text-align: center;
  border: none !important;
  border-radius: 0 !important;
}

.header-lang-holder {
  top: 24px !important;
  margin-left: 20px;
  height: 24px !important;
  font-weight: 700;
  color: #fff !important;
}

.flag-img,
.header-lang-holder img {
  height: 24px !important;
  min-width: 24px !important;
}

.box-shadow-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3) !important;
}

.text-shadow-2 {
  text-shadow: 2px 8px 6px rgba(38, 50, 56, 0.5),
    0 -5px 35px rgba(33, 33, 33, 0.2) !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0 -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.headerForm .ant-btn.login-btn {
  display: block;
  color: #fff !important;
  font-weight: lighter !important;
  background-color: #2196f3 !important;
  background-color: var(--cp-2) !important;
  top: 24px !important;
}

.extendedHeader div {
  min-height: 100% !important;
  width: 175px;
  display: inline-block;
  text-transform: uppercase;
  font-weight: lighter;
}

.extendedHeader .left-nav {
  flex: 1 1 auto;
  min-width: 10%;
  max-width: 90%;
}

.extendedHeader .right-nav {
  flex: 0 0 auto;
  min-width: 10%;
}

.extendedHeader .left-nav a {
  float: left;
}

.extendedHeader .right-nav a {
  float: right;
}

.extendedHeader .right-nav .nav-time {
  float: right;
  margin-left: 10px;
  color: #e7dc3e;
  color: var(--cp-focus);
}

.extendedHeader .link-more {
  float: right;
  margin: 0 30px;
  text-transform: none;
}

.extendedHeader .link-button {
  background-color: #4cae4e !important;
  background-color: var(--cp-1) !important;
  color: #fff !important;
}

.extendedHeader .header-link.active:hover,
.extendedHeader .link-button.active:hover {
  color: #fff !important;
  background-color: rgba(76, 174, 78, 0.3) !important;
  background-color: rgba(var(--cp1-rgb), 0.3) !important;
}

.extendedHeader .header-link.active,
.extendedHeader .header-link:hover {
  color: #99b699 !important;
  color: var(--cp-1c) !important;
  border-bottom: 0.2rem solid #4cae4e;
  border-bottom: 0.2rem solid var(--cp-1);
  background-color: rgba(33, 33, 33, 0.3) !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  height: 100% !important;
}

.extendedHeader .link-button.active {
  color: #fff !important;
  background-color: #4cae4e !important;
  background-color: var(--cp-1) !important;
  border-color: #fff !important;
}

.header-link .anticon {
  margin: 0 8px;
}

.header-link-text .anticon,
.header-link .anticon {
  font-weight: lighter;
  color: #fff !important;
  font-size: larger !important;
  min-height: 24px !important;
}

.header-link-text .anticon {
  margin-right: 8px;
}

.header-link .anticon svg {
  padding-bottom: 4px;
}

.header-link-text .anticon svg,
.header-link .anticon svg {
  min-height: 24px !important;
  margin-right: 5px;
  color: #fff !important;
}

.header-link-text .anticon svg {
  padding-bottom: 5px;
}

.header-link.active .anticon svg {
  color: #e7dc3e !important;
  color: var(--cp-focus) !important;
  text-shadow: 2px 8px 6px rgba(38, 50, 56, 0.5),
    0 -5px 35px rgba(33, 33, 33, 0.2) !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0 -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.header-link.link-button.active i {
  color: #fff !important;
}

.anticon-loading {
  margin-right: 10px !important;
}

/*# sourceMappingURL=main.02976c9a.chunk.css.map */


.ant-collapse-borderless>.ant-collapse-item {
  border-bottom: 1px solid transparent !important;
}

.horizontalMenu {
  /* height:60px; */
  display: flex;
  overflow-x: auto;
  white-space: nowrap;
  background-color: #37474f; /* pending */
}   
/* width */
.horizontalMenu::-webkit-scrollbar {
  width: 0px;
  height:5px;
}

/* float date filter while scrolling  */
html:not([data-scroll='0']) .filterDate {
  position: sticky; 
  right: 0;
}

.mainMenus::-webkit-scrollbar {
  width: 0px;
  height:0px !important;
}

/* Track */
.horizontalMenu::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 5px;
}
 
/* Handle */
.horizontalMenu::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 5px;
}

/* Handle on hover */
.horizontalMenu::-webkit-scrollbar-thumb:hover {
  background: #4cae4e; 
}
.horizontalMenuItem {
  width: 100px;
  flex-shrink: 0;
  height: 45px;
  /* margin-right: 10px; */
  background-color: #37474f;
  /* border-radius: 5px; */
}
.active,
.horizontalMenuItem:active {
  background-color: rgba(33, 33, 33, 0.3) !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  border-bottom: 3px solid #4cae4e;
  cursor:pointer;
}	

.horizontalMenuItem:hover {
  background-color: rgba(33, 33, 33, 0.3) !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  border-bottom: 3px solid #4cae4e;
  cursor:pointer;
}		


.horizontalMenuItem1::before {
  position: absolute;
  content: '';
  background: #283593;
  height: 12px;
  width: 12px;
  /* Added lines */
  top:1.1rem;
  left:-0.3rem;
  transform : rotate(45deg);
}	

.horizontalMenuItem1::after {
  position: absolute;
  content: 'New';
  top: 2px;
  left: -7px;
  /* padding: 0.5rem; */
  width: 5rem;
  background: #3949ab;
  color: white;
  text-align: center;
  font-family: 'Roboto', sans-serif;
  box-shadow: 4px 4px 15px rgba(26, 35, 126, 0.2);
}	


.horizontalSportType {
  display: flex;
  overflow-x: scroll;
  white-space: nowrap;
  height:50px; 
  width: 90%;
}   

/* width */
.horizontalSportType::-webkit-scrollbar {
  width: 3px;
  height:3px;
}

/* Track */
.horizontalSportType::-webkit-scrollbar-track {
  box-shadow: inset 0 0 2px grey; 
  border-radius: 3px;
}
 
/* Handle */
.horizontalSportType::-webkit-scrollbar-thumb {
  background: grey; 
  border-radius: 3px;
}

/* Button on scrollbar--horizontal scrollbar to left*/
.horizontalSportType::-webkit-scrollbar-button::horizontal:decrement {
  border-top: 1px solid #edf2f9;
  width: 26px;
  height: 60px;
  background: url(../assets/arrow-pointing-to-right.svg) 9px 7px no-repeat;
  transform: rotate(180deg);
  border-top: 1px solid #f1f5fa;
  border-bottom: 1px solid #f1f5fa;
  border-right:1px solid #f1f5fa;
}

/* Button on scrollbar--horizontal scrollbar to right*/
.horizontalSportType::-webkit-scrollbar-button::horizontal:increment {
  border-top: 1px solid #edf2f9;
  width: 25px;
  height: 60px;
  background: url(../assets/arrow-pointing-to-right.svg) 9px 7px no-repeat;
  border-left:1px solid #f1f5fa;
}

.horizontalSportTypeItem {
  width: 80px;
  flex-shrink: 0;
  height: 45px;
}
.active,
.horizontalSportTypeItem:active {
  background-color: rgba(33, 33, 33, 0.3) !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  border-bottom: 1px solid #4cae4e;
}

.horizontalSportTypeItem:hover {
  background-color: rgba(33, 33, 33, 0.3) !important;
  /* background-color: rgba(var(--cp3-rgb), 0.3) !important; */
  border-bottom: 1px solid #4cae4e;
  cursor: pointer;
}

.jackpotMenu {
  /* height:33px; */
  display: flex;
  overflow-x: auto;
  /* margin-left:10px; */
  /* margin-bottom:10px; */
  white-space: nowrap;
}
.jackpotMenuItem {
  width: 160px;
  height:33px;
  background-color:#37474f;
  flex-shrink: 0;
  height: 33px;
  margin-right: 2px;
  /* margin-bottom:10px; */
  /* background-color: #37474f; */
  /* border-radius: 3px; */
}
.active,
.jackpotMenuItem:hover {
  background-color:gray !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  border-bottom: 2px solid #4cae4e;
  cursor:pointer;
}
.menuItemTime {
  background-color: #37474f;
  color: white;
  font-weight: 500;
  font-size: 14px;
}
.menuItemTime:hover {
  background-color: rgba(33, 33, 33, 0.3) !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  border-bottom: 3px solid #4cae4e;
  cursor:pointer;
}

/* .menuItemTime:hover {
  border-bottom: 3px solid #fff !important;
} */

/* .topLeagues:hover {
  color:#4cae4e !important;
} */
.active, 
.topLeaguesRow:hover {
  color:#4cae4e !important;
  background-color:rgb(41, 58, 66) !important;
} 

.walletTabs .ant-tabs-tab  {
  margin:0 0px 0 0 !important
}
.jackpotRow {
  color: 'white'; 
  padding-bottom:1px;
  margin:0 5px;
}

.jackpotItem:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}
.jackpotItem:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.jackpotItem {
  display: flex; 
  flex-direction: column;
  background-color:#26323b;
  padding: 10px;
}
.jackpotItemDate {
  display:flex;
  justify-content:space-between;
}
.jackpotItemContent {
  display:flex;
  flex-direction: column;
}

.jackpotHistoryItem {
  display: flex; 
  flex-direction: column;
  background-color:#fff;
  padding: 10px;
}

.jackpotHistoryItem:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
.jackpotHistoryItem:first-child {
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}


.separator {
  border-bottom: 2px solid #fff;
  opacity: 0.2;
  height: 2px;
  /* margin: 0 9px; */
}
.row-separator {
  margin: 12px auto 0;
}

.jackpot-details .button-archive-nav {
  margin: 0px 8px 0 8px;
  transition: all 0.2s;
  font-size: 3em;
  width: 48px;
  height: 48px;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.1);
  line-height: 43px;
}

.jackpot-details {
  text-align: center;
  line-height: 27px;

  color: #fdfffe;
  font-weight: bold;
  opacity: 0.9;
  font-size: 20px;
}
.jackpot-id {
  color: #feffff;
  opacity: 0.6;
  font-size: 12px;
}
.button-archive-nav:hover {
  cursor: pointer;
  opacity: 0.6;
}

.button-archive-nav-disable {
  opacity: 0.5;
}
.button-archive-nav-disable:hover {
  cursor:not-allowed;
  opacity: 0.5;
}

@media (min-width:920px) {
  .jackpotItem {
    flex-direction: row !important;
    justify-content:space-between;
  }
  .jackpotHistoryItem{
    flex-direction: row !important;
    justify-content:space-between;
  }
  .jackpotItemDate {
    flex: 1;
    display: flex;
    justify-content:space-between;

  }
  .jackpotItemContent {
    display:flex;
    flex: 5;
    flex-direction: row;
    justify-content: space-between;
  }
  .jackpotMatch {
    flex: 2;
    /* justify-content: flex-end; */
    margin-left: 20px;
  }
  .jackpotBetId{
    /* flex: 2; */
    margin-left: 20px;
    margin-right: 20px;
  }
  .jackpotYourpick {
    flex:1;
    justify-content: flex-start;
  }
  .jackpotResult {
    flex: 1;
    justify-content: flex-start;
  }
}

.archived-jackpot-row{
  transition: all 0.2s;
  padding: 8px;
  color: white;
  border-radius: 8px;
  background-color: #17242f;
  box-shadow: 0 0 8px 0 rgb(0 0 0 / 50%);
  margin: 8px 0;
}
.jackpot-hove:hover {
  background-color: #1e2d3b;
  cursor: pointer;
}
.jackpot-history-hove:hover {
  /* background-color: #1e2d3b; */
  opacity: 0.6;
  cursor: pointer;
}
.rowjackpot{
  display: flex;
  flex-wrap: wrap;
  margin-left: 10px;
  margin-right: 10px;
}
.jackpot-id{
  opacity: 0.6;
}
.next-jackpot-icon {
  transition: all 0.2s;
  font-size: 2.5em;
  font-weight: 100;
  line-height: 36px;
}
.next-column{
  text-align: right;
}
.jackpot-canceled{
  text-transform: uppercase;
  text-align: center;
  background-color: #9a9fa4;
  border-radius: 3px;
  padding: 4px 8px 4px 8px;
  width: 25px;
  color: #042533;
  opacity: 0.9;
  font-size: 12px;
  font-weight: bold;
  line-height: 36px;
}
.archived-jackpot-centered {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  padding: 24px;
  /* width: 100%; */
  /* border-radius: 8px; */
  /* margin-top: 8px;
  margin-bottom: 8px; */
  background-color: #131e28;
  min-height: 83px;
}
.archived-jackpot-centered .info-text {
  display: flex;
  align-items: center;
  color: white;
  margin: 16px;
}

.action-button .archive-button {
  height: 38px;
  min-width: 172px;
  border-radius: 6.66px;
  background-color: #182836;
  box-shadow: 0 2px 10px 0 rgb(20 28 36 / 70%);
  border: 0;
  /* margin: 24px; */
}

@media only screen and (max-width:707px) {
  .action-button {
    padding: 10px !important;
    height: 48px;
    min-width: 224px;
  }
  .archive-button {
    height: 48px;
    min-width: 224px;
    font-weight: 700;
  }
  .archived-jackpot-centered{
    /* display: flex;
    flex-wrap: wrap; */
    justify-content: center !important;
    margin-bottom: 10px !important;
  }
}
 .action-button .archive-button:hover {
  background-color: #213c54;
}

 .action-button > span {
  color: #00a8fa;
  font-weight: 600;
  font-size: 12px;
  text-align: center;
}

.jackpot-header{
  display: flex;
  justify-content: center;
}

.jackpot-header-title{
  display: flex;
  text-align: center;
  letter-spacing: 3px;
  -ms-flex-wrap: nowrap;
  flex-wrap: nowrap;
  font-weight: 800;
  padding: 0px 6px;
  width: 100%;
}
.jackpot-header-title > div:first-child{
  width: 50%;
  display: flex;
}
.jackpot-header-title > div:last-child{
  width: 50%;
  display: flex;
}
.jackpot-back-button{
  font-weight: 800;
  font-size: 1.5em;
  line-height: 25px;
  width: 24px;
}
.jackpot-back-button:hover{
  opacity: 0.6;
  cursor: pointer;
}

.base-timer {
  position: relative;
  width: 60%;
  left: 50%;
  transform: translateX(-50%);
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.time-group{
margin: 1px;
display: flex;
flex-direction: column;
align-items: center;
justify-content: center;
width: 25%;
}