/* 
    <div class="ant-col ant-col-5 leftNavSection ">
        <div id="listScroll" class="ant-card">
            <div class="ant-card-body sidebar-sport box-shadow-1">
                <div class="ant-collapse-header Soccer active sidebar-sport-name">
                     <img src="" class="sport-image">
                     <span class="sidebar-sport-name-text"> ** Sport Name Here ** </span>
                     <i class="anticon">
                <div class="sidebar-sport-content ant-collapse ant-collapse-borderless">
                    <div class="ant-collapse-item sidebar-inplay">
                        <div class="ant-collapse-header sidebar-inplay-name">
                            <img src="" class="sport-image">
                            <span class="sidebar-sport-name-text"> ** inplay Name Here ** </span>
                            <i class="anticon">
                        <div class="sidebar-inplay-content  ant-collapse-content ant-collapse-content-active" >
                            <div class="ant-collapse ant-collapse-borderless">
                                <div class="ant-collapse-item sidebar-inplay-content-item">
                                    <div class="ant-collapse-header sidebar-inplay-content-item-name" >
                                        <img src="" class="sport-image">
                                        <span class="sidebar-sport-name-text"> ** item Name Here ** </span>
                                        <i class="anticon">
                                    <div class="sidebar-inplay-content-item-content ant-collapse-content ant-collapse-content-active">
                                        <div class="sidebar-inplay-content-item-list">
                                            <span class="sidebar-sport-name-text"> ** tem-List Name Here ** </span>
                                            <span class="sidebar-sport-count-text"> ** tem-List Value Here ** </span>
                                                        


*/

.mainHeader {
  color: white !important;
  background-color: var(--cp-1) !important;
  padding: 0 10px 0px 10px !important;
  position: fixed !important;
  display: flex !important;
  height: var(--mainheader-height) !important;
  height: calc(var(--mainheader-height) + 0.3vw) !important;
  width: 100% !important;
  z-index: 5 !important;
}

.extendedHeader {
  color: white !important;
  background-color: var(--cp-3b) !important;
  padding: 0 calc(3px + 0.5vw) !important;
  position: fixed !important;
  display: flex !important;
  line-height: calc(var(--extendedHeader-height) + 0.3vw) !important;
  height: calc(var(--extendedHeader-height) + 0.3vw) !important;
  min-height: var(--extendedHeader-height) !important;
  margin-top: var(--mainheader-height) !important;
  width: 100% !important;
  z-index: 5 !important;
}

.mainHeader .left-nav {
  flex: 0 0 auto;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: center !important;
  -ms-flex-align: center;
  align-items: center;
  line-height: 100% !important;
  vertical-align: middle !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  margin: auto 10px auto auto !important;
  text-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.02),
    0 3px 1px -2px rgba(0, 0, 0, 0.1) !important;
}

.mainHeader .left-nav .anticon {
  line-height: 100% !important;
  display: inline !important;
  vertical-align: middle !important;
  font-size: 22px !important;
  font-weight: bold !important;
  margin: auto 0 auto 0 !important;
  padding-top: unset !important;
  padding-left: unset !important;
}

.mainHeader .mainLogoContainer {
  flex: 1 1 auto;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center !important;
  -ms-flex-align: center;
  align-items: center;
  font-weight: lighter !important;
  font-size: calc(0.4rem + 0.5vw) !important;
  color: white !important;
  height: 100% !important;
  min-height: 100% !important;
  padding: 0 !important;
}

.mainHeader .right-nav {
  flex: 0 0 auto;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end !important;
  -ms-flex-align: end;
  align-items: end;
}

.mainHeader .mainLogoContainer img.mainLogo {
  padding: 0px !important;
  height: inherit !important;
  min-height: var(--logo-height) !important;
  max-height: calc(var(--logo-height) + 50%) !important;
  width: auto !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
}

.mainHeader > .right-nav > .ant-row {
  font-weight: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  flex: 1 1 auto !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto 0 auto 0 !important;
  display: flex !important;
  min-height: inherit !important;
  width: 100% !important;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.mainHeader .right-nav .header-item {
  font-weight: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  flex: 1 1 auto !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: 3.5px 0 3.5px 0 !important;
  min-height: inherit !important;
  padding: auto 10px auto 0 !important;
  padding-left: 0 !important;
  padding-right: 10px !important;
  width: max-content !important;
  max-width: 240px !important;
  min-width: 135px !important;
  display: none;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.mainHeader .right-nav .header-item.header-item-btn {
  flex: 0 0 auto !important;
  min-width: max-content !important;
}

.mainHeader .right-nav .header-item.header-item-lang {
  flex: 0 0 auto !important;
  min-width: max-content !important;
  margin-left: 20px !important;
}

.headerRight .header-form-holder {
  width: 100% !important;
  min-width: 100% !important;
}

.mainHeader .ant-input-affix-wrapper {
  min-width: 100% !important;
}

.mainHeader .ant-input-affix-wrapper input.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-weight: inherit !important;
  font-size: inherit !important;
  color: var(--cp-1) !important;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 100%;
  padding: 5px 3px 5px 35px !important;
  background-color: #fff;
  background-image: none;
  border: none !important;
  border-radius: 0px !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.02),
    0 3px 1px -2px rgba(0, 0, 0, 0.1) !important;
}

.mainHeader .ant-input-affix-wrapper .ant-input-prefix,
.mainHeader .ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  line-height: 20px;
  left: 0;
  height: 100% !important;
  font-weight: bold !important;
  font-size: 12px !important;
  color: var(--cp-2) !important;
  width: auto;
  min-width: 25px !important;
  max-width: 30px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-right: 0.003rem solid rgba(var(--cp2-rgb), 0.2);
}

.mainHeader .ant-input-affix-wrapper .ant-input-prefix > .anticon,
.mainHeader .ant-input-affix-wrapper .ant-input-suffix > .anticon {
  color: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
}

.mainHeader .header-item-btn button.ant-btn.LoginButton {
  margin: 0;
  font-weight: bold !important;
  font-size: inherit !important;
  color: inherit !important;
  line-height: 100% !important;
  line-height: 100%;
  height: auto !important;
  width: 100% !important;
  padding: 7px 15px !important;
  text-align: center;
  border: none !important;
  border-radius: 0px !important;
  background-color: var(--cp-2) !important;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3) !important;
}

.header-link-text {
  width: 100% !important;
  height: 28px !important;
  min-height: 28px !important;
  max-height: 28px !important;
  line-height: 100%;
  padding: 5px 2px 5px 2px !important;
  min-width: 100% !important;
}

.headerLink {
  /* padding: 0 calc(3px + 0.5vw) 0 calc(3px + 0.5vw) !important; */
  display: block !important;
  font-weight: bold !important;
  font-size: inherit !important;
  height: 100% !important;
  min-height: 100% !important;
  color: rgba(255, 255, 255, 0.85) !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0px -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.extendedHeader .left-nav {
  flex: 1 1 auto;
  min-width: 10%;
  max-width: 90%;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.extendedHeader .right-nav {
  flex: 1 1 auto;
  min-width: 30%;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.extendedHeader .menuItem {
  cursor: pointer !important;
  padding: 0 1vw 0 1vw !important;
  padding: 0 calc(3px + 1vw) 0 calc(3px + 1vw) !important;
  display: none;
  font-size: calc(0.4rem + 0.7vw) !important;
  font-weight: bold !important;
  color: rgba(255, 255, 255, 0.75) !important;
  height: 100% !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0px -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
  background-color: transparent !important;
  width: max-content !important;
  justify-content: center !important;
  top: 0;
  bottom: 0;
  vertical-align: middle !important;
  margin: auto 0 auto 0 !important;
  border-bottom: 0.2rem solid rgba(var(--cp2-rgb), 0) !important;
}

.header-item.show-any,
.menuItem.show-any,
.header-item.mob-only,
.menuItem.mob-only {
  display: flex !important;
}

.extendedHeader .menuItem .anticon {
  line-height: 100% !important;
  display: inline !important;
  vertical-align: middle !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  margin: auto 10px auto 0 !important;
}

.extendedHeader .menuItem > img {
  line-height: 100% !important;
  display: inline !important;
  vertical-align: middle !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  margin: auto 10px auto 0 !important;
}

.extendedHeader .menuItem.menuItemButton {
  background-color: var(--cp-2) !important;
  margin-right: 0.2rem !important;
}

.extendedHeader .menuItem.active {
  color: white !important;
  background-color: rgba(var(--cp4-rgb), 0.3) !important;
  border-bottom: 0.2rem solid rgba(var(--cp2-rgb), 0.8) !important;
}

.extendedHeader .menuItem.menuItemButton.active,
.extendedHeader .menuItem.menuItemButton:hover {
  background-color: var(--cp-2) !important;
  border-bottom: 0.2rem solid rgba(255, 255, 255, 1) !important;
}

.extendedHeader .menuItem:hover {
  color: white !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  border-bottom: 0.2rem solid rgba(255, 255, 255, 0.3) !important;
}

.extendedHeader .menuItem.active:hover {
  border-bottom: 0.2rem solid rgba(var(--cp2-rgb), 0.4) !important;
}

.extendedHeader .menuItem-time {
  float: right !important;
  width: max-content !important;
  margin-left: 20px !important;
  font-size: calc(0.4rem + 0.6vw) !important;
  font-weight: lighter !important;
  color: var(--cp-focus) !important;
}

.menuItem-time > span,
.menuItem-time time {
  font-size: inherit !important;
  font-weight: inherit !important;
  color: inherit !important;
  text-shadow: inherit !important;
}
/*
.headerForm {
    margin: auto 0 auto 0 !important;
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
}




.button-holder {
    margin: 0 0 0 10px !important;
    min-height: 100% !important;
    bottom: 0 !important;
}

.headerForm .ant-input,
.headerForm .ant-btn.login-btn {
    height: 24px !important;
    min-height: 24px !important;
    top: 0;
    color: var(--cp-4);
    text-align: center;
    border: none !important;
    border-radius: 0px !important;
}

.header-lang-holder {
    top: 24px !important;
    margin-left: 20px;
    height: 24px !important;
    font-weight: bold;
    color: white !important;
}

.header-lang-holder img,
.flag-img {
    height: 24px !important;
    min-width: 24px !important;
}



.text-shadow-2 {
    text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5), 0px -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.headerForm .ant-btn.login-btn {
    display: block;
    color: white !important;
    font-weight: lighter !important;
    background-color: var(--cp-2) !important;
    top: 24px !important;
}

.extendedHeader div {
    min-height: 100% !important;
    text-transform: uppercase;
    font-weight: lighter;
}

.extendedHeader .left-nav {
    flex: 1 1 auto;
    min-width: 10%;
    max-width: 90%;
}

.extendedHeader .right-nav {
    flex: 0 0 auto;
    min-width: 10%;
}

.extendedHeader .left-nav a {
    float: left;
}

.extendedHeader .right-nav a {
    float: right;
}

.extendedHeader .right-nav .nav-time {
    float: right;
    margin-left: 10px;
    color: var(--cp-focus);
}

.extendedHeader .link-more {
    float: right;
    margin: 0 30px;
    text-transform: none;
}

.extendedHeader .header-link:hover,
.extendedHeader .header-link.active {
    color: white !important;
    border-bottom: 0.2rem solid rgba(255, 255, 255, 0.5);
    background-color: rgba( var(--cp3-rgb), 0.3) !important;
    border-bottom: 0.2rem solid rgba( var(--cp2-rgb), 0.8) !important;
}

.extendedHeader .header-link.active:hover {
    background-color: rgba( var(--cp3-rgb), 0.5) !important;
}

.extendedHeader .header-link.link-button {
    background-color: var(--cp-2) !important;
    text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5), 0px -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.header-link .anticon {
    text-align: center;
    margin: auto !important;
    padding: 0 5px;
    font-size: 1vw !important;
    height: 100% !important;
    top: 0;
    bottom: 0;
    color: rgba(255, 255, 255, 0.75) !important;
    vertical-align: middle;
    display: flex;
    line-height: inherit;
}

.header-link.active .anticon {
    color: white !important;
}

.header-link-text .anticon {
    margin-right: 8px;
    font-weight: lighter;
    color: white !important;
    font-size: larger !important;
    min-height: 24px !important;
}

.header-link .anticon svg {
    height: 100% !important;
    color: white !important;
    margin-right: 5px !important;
}

.header-link-text .anticon svg {
    min-height: 24px !important;
    margin-right: 5px;
    color: white !important;
    padding-bottom: 5px;
}

.header-link.active .anticon svg {
    color: var(--cp-focus) !important;
    text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5), 0px -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.header-link.link-button .anticon svg {
    color: white !important;
}

body {
    background-color: var(--cp-4) !important;
}

#root {
    background-color: var(--cp-4) !important;
}

.MainBody {
    padding: 10px 7px !important;
} */

.box-shadow-1 {
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12),
    0 3px 1px -2px rgba(0, 0, 0, 0.2) !important;
}

.box-shadow-2 {
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3) !important;
}

.withdrawMoney{
  display: flex;
  flex-direction: column;
}
.withdrawItem{
  display: flex;
  justify-content: space-between;
}