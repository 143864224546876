.bc-icons.breadcrumb-item::before,
.bc-icons.breadcrumb-item::after {
    content: none;
}

.bc-icons.breadcrumb-item+.active.breadcrumb-item::before,
.bc-icons.breadcrumb-item+.active.breadcrumb-item::after {
    content: none;
}

.bc-icons.breadcrumb-item.active {
    color: #eeeeee;
}

.btn-group-vertical>.btn,
.btn-group-vertical>.btn+.btn-group,
.btn-group-vertical>.btn-group+.btn,
.btn-group-vertical>.btn-group+.btn-group {
    margin-left: 0px;
}

.btn-group-lg>.btn {
    font-size: 0.9rem;
    padding: 1rem 2.4rem;
}

.btn-group-sm>.btn {
    font-size: 0.6rem;
    padding: 0.5rem 1.6rem;
}

.btn-floating.btn.btn-sm,
.btn-floating.btn.btn-lg {
    padding: 0;
}

.Ripple {
    position: absolute;
    background: rgba(255, 255, 255, 0.3);
    border-radius: 50%;
    opacity: 1;
    transform: scale(0);
}

.Ripple-outline {
    background: rgba(0, 0, 0, 0.2);
}

.Ripple.is-reppling {
    animation: ripple 0.5s linear;
}

.Ripple-parent {
    position: relative;
    overflow: hidden;
    cursor: pointer;
}

@keyframes ripple {
    100% {
        opacity: 0;
        transform: scale(3);
    }
}

.carousel-inner {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100%;
}

.carousel-fade .carousel-item {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: block !important;
    opacity: 0;
    z-index: 0;
    transition: transform 0ms ease-in-out, opacity 0.8s ease-out;
}

.carousel-fade .carousel-item.active {
    position: relative;
    z-index: 1;
    opacity: 1;
}

.carousel-multi-item .carousel-item {
    display: inline-block !important;
}

.carousel .carousel-slide-item {
    transition: left 0.5s;
}

.carousel-control-prev,
.carousel-control-next,
.carousel-item-prev,
.carousel-item-next {
    z-index: 2;
}

.table-wrapper-scroll-y {
    display: block;
    max-height: 200px;
    overflow-y: auto;
    -ms-overflow-style: -ms-autohiding-scrollbar;
}

div.mdb-datatable div.mdb-datatable-length select,
div.mdb-datatable div.mdb-datatable-length input {
    width: auto;
}

div.mdb-datatable div.mdb-datatable-length.d-flex.flex-row label {
    margin-top: 1.2rem;
    margin-right: 1rem;
}

div.mdb-datatable div.mdb-datatable-length.d-flex.flex-row .select-wrapper.mdb-select span,
div.mdb-datatable div.mdb-datatable-length.d-flex.flex-row .select-wrapper.mdb-select .select-dropdown {
    margin-top: 1rem;
}

div.mdb-datatable div.mdb-datatable-length label,
div.mdb-datatable div.mdb-datatable-filter label {
    font-weight: 400;
    text-align: left;
    margin-bottom: 0;
}

div.mdb-datatable div.mdb-datatable-filter {
    text-align: right;
}

div.mdb-datatable div.mdb-datatable-filter select,
div.mdb-datatable div.mdb-datatable-filter input {
    width: auto;
}

div.mdb-datatable div.mdb-datatable-filter input {
    display: inline-block;
    margin-left: .5rem;
}

div.mdb-datatable div.mdb-datatable-info,
div.mdb-datatable div.mdb-dataTables_paginate,
div.mdb-datatable div.mdb-datatable-entries {
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-weight: 400;
    font-size: .9rem;
    padding-left: .7rem;
    padding-right: .7rem;
}

div.mdb-datatable div.mdb-datatable-info {
    display: flex;
    justify-content: center;
}

div.mdb-datatable div.mdb-dataTables_paginate {
    margin: 0;
    text-align: right;
}

div.mdb-datatable div.mdb-dataTables_paginate ul.pagination {
    -ms-flex-pack: end;
    -webkit-justify-content: flex-end;
    justify-content: flex-end;
    -webkit-box-pack: end;
}

div.mdb-datatable div.mdb-dataTables_paginate ul.pagination .page-item.active .page-link {
    background-color: #7e7e7e;
}

div.mdb-datatable div.mdb-dataTables_paginate ul.pagination .page-item.active .page-link:focus {
    background-color: #7e7e7e;
}

div.mdb-datatable div.mdb-dataTables_paginate ul.pagination .page-item .page-link:focus {
    -webkit-box-shadow: none;
    box-shadow: none;
}

@media (max-width: 767px) {
    div.mdb-datatable div .mdb-datatable-length,
    div.mdb-datatable div .mdb-datatable-filter,
    div.mdb-datatable div .mdb-datatable-info,
    div.mdb-datatable div .mdb-dataTables_paginate ul.pagination {
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        text-align: center;
        -webkit-box-pack: center;
    }
}

.bs-select select {
    display: inline-block !important;
}

table.mdb-dataTable thead,
table thead.mdb-dataTable-head {
    cursor: pointer;
}

table.mdb-dataTable thead th,
table thead.mdb-dataTable-head th {
    font-weight: 600;
}

table.mdb-dataTable thead>tr>th.sorting_asc,
table.mdb-dataTable thead>tr>th.sorting_desc,
table.mdb-dataTable thead>tr>th.sorting,
table.mdb-dataTable thead>tr>td.sorting_asc,
table.mdb-dataTable thead>tr>td.sorting_desc,
table.mdb-dataTable thead>tr>td.sorting,
table thead.mdb-dataTable-head>tr>th.sorting_asc,
table thead.mdb-dataTable-head>tr>th.sorting_desc,
table thead.mdb-dataTable-head>tr>th.sorting,
table thead.mdb-dataTable-head>tr>td.sorting_asc,
table thead.mdb-dataTable-head>tr>td.sorting_desc,
table thead.mdb-dataTable-head>tr>td.sorting {
    padding-right: 30px;
}

table.mdb-dataTable thead>tr>th:active,
table.mdb-dataTable thead>tr>td:active,
table thead.mdb-dataTable-head>tr>th:active,
table thead.mdb-dataTable-head>tr>td:active {
    outline: none;
}

table.mdb-dataTable thead .sorting,
table.mdb-dataTable thead .sorting_asc,
table.mdb-dataTable thead .sorting_desc,
table.mdb-dataTable thead .sorting_asc_disabled,
table.mdb-dataTable thead .sorting_desc_disabled,
table thead.mdb-dataTable-head .sorting,
table thead.mdb-dataTable-head .sorting_asc,
table thead.mdb-dataTable-head .sorting_desc,
table thead.mdb-dataTable-head .sorting_asc_disabled,
table thead.mdb-dataTable-head .sorting_desc_disabled {
    position: relative;
    cursor: pointer;
}

table.mdb-dataTable thead .sorting:before,
table.mdb-dataTable thead .sorting:after,
table.mdb-dataTable thead .sorting_asc:before,
table.mdb-dataTable thead .sorting_asc:after,
table.mdb-dataTable thead .sorting_desc:before,
table.mdb-dataTable thead .sorting_desc:after,
table.mdb-dataTable thead .sorting_asc_disabled:before,
table.mdb-dataTable thead .sorting_asc_disabled:after,
table.mdb-dataTable thead .sorting_desc_disabled:before,
table.mdb-dataTable thead .sorting_desc_disabled:after,
table thead.mdb-dataTable-head .sorting:before,
table thead.mdb-dataTable-head .sorting:after,
table thead.mdb-dataTable-head .sorting_asc:before,
table thead.mdb-dataTable-head .sorting_asc:after,
table thead.mdb-dataTable-head .sorting_desc:before,
table thead.mdb-dataTable-head .sorting_desc:after,
table thead.mdb-dataTable-head .sorting_asc_disabled:before,
table thead.mdb-dataTable-head .sorting_asc_disabled:after,
table thead.mdb-dataTable-head .sorting_desc_disabled:before,
table thead.mdb-dataTable-head .sorting_desc_disabled:after {
    position: absolute;
    bottom: 1rem;
    display: block;
    opacity: 0;
}

table.mdb-dataTable thead .sorting:hover::before,
table thead.mdb-dataTable-head .sorting:hover::before {
    opacity: 0.6;
}

table.mdb-dataTable thead .sorting:before,
table.mdb-dataTable thead .sorting_asc:before,
table.mdb-dataTable thead .sorting_desc:before,
table.mdb-dataTable thead .sorting_asc_disabled:before,
table.mdb-dataTable thead .sorting_desc_disabled:before,
table thead.mdb-dataTable-head .sorting:before,
table thead.mdb-dataTable-head .sorting_asc:before,
table thead.mdb-dataTable-head .sorting_desc:before,
table thead.mdb-dataTable-head .sorting_asc_disabled:before,
table thead.mdb-dataTable-head .sorting_desc_disabled:before {
    left: -.4rem;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f062";
}

table.mdb-dataTable thead .sorting:after,
table.mdb-dataTable thead .sorting_asc:after,
table.mdb-dataTable thead .sorting_desc:after,
table.mdb-dataTable thead .sorting_asc_disabled:after,
table.mdb-dataTable thead .sorting_desc_disabled:after,
table thead.mdb-dataTable-head .sorting:after,
table thead.mdb-dataTable-head .sorting_asc:after,
table thead.mdb-dataTable-head .sorting_desc:after,
table thead.mdb-dataTable-head .sorting_asc_disabled:after,
table thead.mdb-dataTable-head .sorting_desc_disabled:after {
    left: -.4rem;
    font-family: "Font Awesome\ 5 Free", sans-serif;
    font-size: 1rem;
    font-weight: 900;
    content: "\f063";
}

table.mdb-dataTable thead .sorting_asc:before,
table.mdb-dataTable thead .sorting_desc:after,
table thead.mdb-dataTable-head .sorting_asc:before,
table thead.mdb-dataTable-head .sorting_desc:after {
    opacity: 1;
}

table.mdb-dataTable thead .sorting_asc_disabled:before,
table.mdb-dataTable thead .sorting_desc_disabled:after,
table thead.mdb-dataTable-head .sorting_asc_disabled:before,
table thead.mdb-dataTable-head .sorting_desc_disabled:after {
    opacity: 0;
}

.mdb-dataTable_scrollBodyHead .dataTables_scrollHeadInner,
.mdb-dataTable_scrollBodyHead .dataTables_scrollHeadInner table {
    padding-right: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
}

.mdb-dataTable_scrollBodyHead .dataTables_scrollHeadInner table {
    margin-bottom: 0 !important;
}

.dataTables_scrollFoot .dataTables_scrollFootInner,
.dataTables_scrollFoot .dataTables_scrollFootInner table {
    padding-right: 0 !important;
    margin-right: 0 !important;
    width: 100% !important;
}

.mdb-datatable table tr {
    border-bottom: 1px solid #dee2e6;
}

table.mdb-dataTable tbody>tr.selected,
table.mdb-dataTable tbody>tr>.selected {
    background-color: #f5f5f5;
}

table.mdb-dataTable tbody>tr:not(.selected):hover {
    background-color: rgba(245, 245, 245, 0.4);
}

table.mdb-dataTable.stripe tbody>tr.odd.selected,
table.mdb-dataTable.stripe tbody>tr.odd>.selected,
table.mdb-dataTable.display tbody>tr.odd.selected,
table.mdb-dataTable.display tbody>tr.odd>.selected {
    background-color: #acbad4;
}

table.mdb-dataTable.hover tbody>tr.selected:hover,
table.mdb-dataTable.hover tbody>tr>.selected:hover,
table.mdb-dataTable.display tbody>tr.selected:hover,
table.mdb-dataTable.display tbody>tr>.selected:hover {
    background-color: #aab7d1;
}

table.mdb-dataTable.order-column tbody>tr.selected>.sorting_1,
table.mdb-dataTable.order-column tbody>tr.selected>.sorting_2,
table.mdb-dataTable.order-column tbody>tr.selected>.sorting_3,
table.mdb-dataTable.display tbody>tr.selected>.sorting_1,
table.mdb-dataTable.display tbody>tr.selected>.sorting_2,
table.mdb-dataTable.display tbody>tr.selected>.sorting_3 {
    background-color: #acbad5;
}

table.mdb-dataTable.order-column tbody>tr>.selected,
table.mdb-dataTable.display tbody>tr>.selected {
    background-color: #acbad5;
}

table.mdb-dataTable.display tbody>tr.odd.selected>.sorting_1,
table.mdb-dataTable.order-column.stripe tbody>tr.odd.selected>.sorting_1 {
    background-color: #a6b4cd;
}

table.mdb-dataTable.display tbody>tr.odd.selected>.sorting_2,
table.mdb-dataTable.order-column.stripe tbody>tr.odd.selected>.sorting_2 {
    background-color: #a8b5cf;
}

table.mdb-dataTable.display tbody>tr.odd.selected>.sorting_3,
table.mdb-dataTable.order-column.stripe tbody>tr.odd.selected>.sorting_3 {
    background-color: #a9b7d1;
}

table.mdb-dataTable.display tbody>tr.even.selected>.sorting_1,
table.mdb-dataTable.order-column.stripe tbody>tr.even.selected>.sorting_1 {
    background-color: #acbad5;
}

table.mdb-dataTable.display tbody>tr.even.selected>.sorting_2,
table.mdb-dataTable.order-column.stripe tbody>tr.even.selected>.sorting_2 {
    background-color: #aebcd6;
}

table.mdb-dataTable.display tbody>tr.even.selected>.sorting_3,
table.mdb-dataTable.order-column.stripe tbody>tr.even.selected>.sorting_3 {
    background-color: #afbdd8;
}

table.mdb-dataTable.display tbody>tr.odd>.selected,
table.mdb-dataTable.order-column.stripe tbody>tr.odd>.selected {
    background-color: #a6b4cd;
}

table.mdb-dataTable.display tbody>tr.even>.selected,
table.mdb-dataTable.order-column.stripe tbody>tr.even>.selected {
    background-color: #acbad5;
}

table.mdb-dataTable.display tbody>tr.selected:hover>.sorting_1,
table.mdb-dataTable.order-column.hover tbody>tr.selected:hover>.sorting_1 {
    background-color: #a2aec7;
}

table.mdb-dataTable.display tbody>tr.selected:hover>.sorting_2,
table.mdb-dataTable.order-column.hover tbody>tr.selected:hover>.sorting_2 {
    background-color: #a3b0c9;
}

table.mdb-dataTable.display tbody>tr.selected:hover>.sorting_3,
table.mdb-dataTable.order-column.hover tbody>tr.selected:hover>.sorting_3 {
    background-color: #a5b2cb;
}

table.mdb-dataTable.display tbody>tr:hover>.selected,
table.mdb-dataTable.display tbody>tr>.selected:hover,
table.mdb-dataTable.order-column.hover tbody>tr:hover>.selected,
table.mdb-dataTable.order-column.hover tbody>tr>.selected:hover {
    background-color: #a2aec7;
}

table.mdb-dataTable tbody td.select-checkbox,
table.mdb-dataTable tbody td.select-checkbox-all,
table.mdb-dataTable tbody th.select-checkbox,
table.mdb-dataTable tbody th.select-checkbox-all,
table.mdb-dataTable thead td.select-checkbox,
table.mdb-dataTable thead td.select-checkbox-all,
table.mdb-dataTable thead th.select-checkbox,
table.mdb-dataTable thead th.select-checkbox-all {
    position: relative;
}

table.mdb-dataTable tbody td.select-checkbox:before,
table.mdb-dataTable tbody td.select-checkbox:after,
table.mdb-dataTable tbody td.select-checkbox-all:before,
table.mdb-dataTable tbody td.select-checkbox-all:after,
table.mdb-dataTable tbody th.select-checkbox:before,
table.mdb-dataTable tbody th.select-checkbox:after,
table.mdb-dataTable tbody th.select-checkbox-all:before,
table.mdb-dataTable tbody th.select-checkbox-all:after,
table.mdb-dataTable thead td.select-checkbox:before,
table.mdb-dataTable thead td.select-checkbox:after,
table.mdb-dataTable thead td.select-checkbox-all:before,
table.mdb-dataTable thead td.select-checkbox-all:after,
table.mdb-dataTable thead th.select-checkbox:before,
table.mdb-dataTable thead th.select-checkbox:after,
table.mdb-dataTable thead th.select-checkbox-all:before,
table.mdb-dataTable thead th.select-checkbox-all:after {
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
    box-sizing: border-box;
    display: block;
    width: 20px;
    height: 20px;
}

table.mdb-dataTable tbody td.select-checkbox:hover,
table.mdb-dataTable tbody td.select-checkbox-all:hover,
table.mdb-dataTable tbody th.select-checkbox:hover,
table.mdb-dataTable tbody th.select-checkbox-all:hover,
table.mdb-dataTable thead td.select-checkbox:hover,
table.mdb-dataTable thead td.select-checkbox-all:hover,
table.mdb-dataTable thead th.select-checkbox:hover,
table.mdb-dataTable thead th.select-checkbox-all:hover {
    cursor: pointer;
}

table.mdb-dataTable tbody td.select-checkbox:before,
table.mdb-dataTable tbody th.select-checkbox.select-checkbox-all:before,
table.mdb-dataTable thead td.select-checkbox:before,
table.mdb-dataTable thead th.select-checkbox.select-checkbox-all:before {
    content: ' ';
    border: 2px solid #5a5a5a;
    border-radius: 3px;
}

table.mdb-dataTable tr.selected td.select-checkbox:after,
table.mdb-dataTable tr.selected th.select-checkbox:after {
    margin-top: 0;
    text-align: center;
    font-family: 'Font Awesome\ 5 Free', sans-serif;
    font-size: 0.9rem;
    font-weight: 600;
    content: '\f00c';
    background-color: #a6c;
    box-shadow: 0 0 1em #5a5a5a;
    color: #fff;
}

div.mdb-datatable span.select-info,
div.mdb-datatable span.select-item {
    margin-left: 0.5em;
}

@media screen and (max-width: 640px) {
    div.mdb-datatable span.select-info,
    div.mdb-datatable span.select-item {
        display: block;
        margin-left: 0;
    }
}

.dropup .dropdown-menu {
    top: auto !important;
    bottom: 100% !important;
    transform: translate3d(5px, 5px, 0px) !important;
}

.dropdown-menu-right {
    left: 0 !important;
    right: auto !important;
}

.hamburger-button__checkbox {
    display: none;
}

.hamburger-button__button {
    background-color: transparent;
    height: 100%;
    width: 100%;
    text-align: center;
    cursor: pointer;
    top: -5px;
}

label.hamburger-button__button {
    margin-bottom: 0;
}

#nav-icon1 {
    width: 1.5em;
    height: 1.5em;
    position: relative;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .5s ease-in-out;
    -moz-transition: .5s ease-in-out;
    -o-transition: .5s ease-in-out;
    transition: .5s ease-in-out;
    cursor: pointer;
}

#nav-icon1 span {
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    border-radius: 1px;
    background-color: #fff;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: .25s ease-in-out;
    -moz-transition: .25s ease-in-out;
    -o-transition: .25s ease-in-out;
    transition: .25s ease-in-out;
}

#nav-icon1 span:nth-child(1) {
    top: 5px;
}

#nav-icon1 span:nth-child(2) {
    top: 16px;
}

#nav-icon1 span:nth-child(3) {
    top: 27px;
}

.hamburger-button__checkbox:checked+#nav-icon1 span:nth-child(1) {
    top: 16px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

.hamburger-button__checkbox:checked+#nav-icon1 span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

.hamburger-button__checkbox:checked+#nav-icon1 span:nth-child(3) {
    top: 16px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

.overflow-y-scroll {
    overflow-y: scroll !important;
}

.popover {
    width: auto;
    background-color: white;
    color: #97999b;
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    position: absolute;
    font-size: 0.83em;
    font-weight: normal;
    border: 1px rgb(0, 0, 0) solid;
    /* z-index: 200000; */
    z-index: 10;
    /* max-width: initial; */
    max-width: 274px;
    text-align: start;
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
}

.show.popover {
    z-index: 999;
    opacity: 1;
    visibility: visible;
}

.popover-body {
    color: #6c6e71;
}

.popover .popover_arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 6px;
    color: transparent;
}

.popover[x-placement^="top"] {
    margin-bottom: 15px;
}

.popover[x-placement^="top"] .popover_arrow {
    border-width: 8px 8px 0 8px;
    border-color: #d6d6d6 transparent transparent transparent;
    bottom: -8px;
    margin-bottom: 0;
}

.popover[x-placement^="top"] .popover_arrow::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -8px;
    bottom: 1.5px;
    border: solid;
    border-width: 8px 8px 0 8px;
    border-color: white transparent transparent transparent;
}

.popover[x-placement^="bottom"] {
    margin-top: 15px;
}

.popover[x-placement^="bottom"] .popover_arrow {
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent #d6d6d6 transparent;
    top: -8px;
    margin-top: 0;
}

.popover[x-placement^="bottom"] .popover_arrow::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -8px;
    top: 1.45px;
    border: solid;
    border-width: 0 8px 8px 8px;
    border-color: transparent transparent white transparent;
}

.popover[x-placement^="right"] {
    margin-left: 15px;
}

.popover[x-placement^="right"] .popover_arrow {
    border-width: 8px 8px 8px 0;
    border-color: transparent #d6d6d6 transparent transparent;
    left: -8px;
    margin-left: 0;
}

.popover[x-placement^="right"] .popover_arrow::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -8px;
    left: 1.45px;
    border: solid;
    border-width: 8px 8px 8px 0;
    border-color: transparent white transparent transparent;
}

.popover[x-placement^="left"] {
    margin-right: 15px;
}

.popover[x-placement^="left"] .popover_arrow {
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent #d6d6d6;
    right: -8px;
    margin-right: 0;
}

.popover[x-placement^="left"] .popover_arrow::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -8px;
    right: 1.45px;
    border: solid;
    border-width: 8px 0 8px 8px;
    border-color: transparent transparent transparent white;
}

.tooltip {
    width: auto;
    background-color: black;
    color: white;
    text-align: center;
    display: inline-block;
    border-radius: 3px;
    position: absolute;
    /* font-size: 0.83em; */
    font-weight: normal;
    border: 1px rgb(0, 0, 0) solid;
    /* z-index: 200000; */
    z-index: 15;
    /* max-width: initial; */
    max-width: 274px;
    text-align: start;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 0.3rem;
    opacity: 0;
    transition: opacity 0.3s, visibility 0.3s;
    visibility: hidden;
}

.tooltip-inner {
    display: block;
}

.show.tooltip {
    z-index: 999;
    opacity: 1;
    visibility: visible;
}

.tooltip .popover_arrow {
    width: 0;
    height: 0;
    border-style: solid;
    position: absolute;
    margin: 6px;
    color: transparent;
}

.tooltip[x-placement^="top"],
.show[x-placement^="top"]:not(.tooltip) {
    margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .popover_arrow {
    border-width: 6px 6px 0 6px;
    border-color: #131313 transparent transparent transparent;
    bottom: -6px;
    margin-bottom: 0;
}

.tooltip[x-placement^="top"] .popover_arrow::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -6px;
    bottom: 1.5px;
    border: solid;
    border-width: 6px 6px 0 6px;
    border-color: black transparent transparent transparent;
}

.tooltip[x-placement^="bottom"],
.show[x-placement^="bottom"]:not(.tooltip) {
    margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .popover_arrow {
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent #131313 transparent;
    top: -6px;
    margin-top: 0;
}

.tooltip[x-placement^="bottom"] .popover_arrow::before {
    content: "";
    display: inline-block;
    position: absolute;
    left: -6px;
    top: 1.45px;
    border: solid;
    border-width: 0 6px 6px 6px;
    border-color: transparent transparent black transparent;
}

.tooltip[x-placement^="right"],
.show[x-placement^="right"]:not(.tooltip) {
    margin-left: 5px;
}

.tooltip[x-placement^="right"] .popover_arrow {
    border-width: 6px 6px 6px 0;
    border-color: transparent #131313 transparent transparent;
    left: -6px;
    margin-left: 0;
}

.tooltip[x-placement^="right"] .popover_arrow::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -6px;
    left: 1.45px;
    border: solid;
    border-width: 6px 6px 6px 0;
    border-color: transparent black transparent transparent;
}

.tooltip[x-placement^="left"],
.show[x-placement^="left"]:not(.tooltip) {
    margin-right: 5px;
}

.tooltip[x-placement^="left"] .popover_arrow {
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent #131313;
    right: -6px;
    margin-right: 0;
}

.tooltip[x-placement^="left"] .popover_arrow::before {
    content: "";
    display: inline-block;
    position: absolute;
    top: -6px;
    right: 1.45px;
    border: solid;
    border-width: 6px 0 6px 6px;
    border-color: transparent transparent transparent black;
}

.note-dark {
    background-color: #000;
    color: #fff;
    border-color: #58595a;
}

.note-default {
    background-color: rgb(164, 243, 235);
    border-color: #00695c;
}

.note-elegant {
    background-color: #2E2E2E;
    border-color: #212121;
    color: #fff;
}

.note-stylish {
    background-color: #4B515D;
    border-color: #3E4551;
    color: #fff;
}

.note-unique {
    background-color: #3F729B;
    border-color: #1C2331;
    color: #fff;
}

.note-special {
    background-color: #37474F;
    border-color: #263238;
    color: #fff;
}