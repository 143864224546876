.hulu-banner-group {
    padding-top: calc(var(--mainheader-height) + var(--extendedHeader-height));
}

.hulu-banner-group,
.banner-img {
    display: block;
    position: relative;
    background-size: cover;
    box-shadow: inset 0 0 15px rgba(37, 36, 36, 0.7);
    width: 100%;
    touch-action: pan-y;
    background-color: rgba(255, 255, 255, 0.85);
}

.hulu-slider {
    text-align: center;
    position: relative;
    height: var(--banner-height);
    max-height: var(--banner-max-height);
    min-height: var(--banner-min-height);
    width: 100%;
}

.hulu-slides {
    background-color: transparent;
    height: 100% !important;
    vertical-align: middle;
    position: relative;
    list-style-type: none;
    display: -webkit-box;
    display: -moz-box;
    display: -ms-flexbox;
    display: -webkit-flex;
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0;
    margin: 0
}

.hulu-slides li {
    top: auto;
    list-style: none;
    opacity: 0;
    position: absolute;
    width: 100%;
    height: inherit;
    overflow: hidden;
    border: none;
}

.hulu-slide-text {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    animation-duration: 5s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
}

.hulu-banner-text-focused {
    width: auto;
    font-weight: lighter;
    font-size: 70px;
    margin: 0 3%;
    color: #ffffff;
    text-shadow: 0 0 20px rgba(0, 0, 0, .95), 0 2px 0 #ea9203;
}

.bg-img-t {
    background-color: transparent;
    background-size: auto;
    background-position: top;
}

.bg-img-r {
    background-color: transparent;
    background-size: auto;
    background-position: left;
}

.bg-img-r {
    background-color: transparent;
    background-size: auto;
    background-position: right;
}

.bg-img-b {
    background-color: transparent;
    background-size: auto;
    background-position: bottom;
}

.bg-img-c {
    background-color: transparent;
    background-size: auto;
    background-position: center;
}

.bg-img-x {
    background-position-x: right;
}

.bg-img-y {
    background-position-y: right;
}

.bg-img-xx {
    background-position-x: right;
    background-repeat: repeat-x;
}

.bg-img-yy {
    background-position-y: right;
    background-repeat: repeat-y;
}

.bg-anim-t {
    -webkit-animation: 400s scroll-top infinite linear;
    -moz-animation: 400s scroll-top infinite linear;
    -o-animation: 400s scroll-top infinite linear;
    -ms-animation: 400s scroll-top infinite linear;
    animation: 400s scroll-top infinite linear
}

.bg-anim-tf {
    -webkit-animation: 100s scroll-top infinite linear;
    -moz-animation: 100s scroll-top infinite linear;
    -o-animation: 100s scroll-top infinite linear;
    -ms-animation: 100s scroll-top infinite linear;
    animation: 100s scroll-top infinite linear
}

.bg-anim-b {
    -webkit-animation: 400s scroll-bottom infinite linear;
    -moz-animation: 400s scroll-bottom infinite linear;
    -o-animation: 400s scroll-bottom infinite linear;
    -ms-animation: 400s scroll-bottom infinite linear;
    animation: 400s scroll-bottom infinite linear
}

.bg-anim-bf {
    -webkit-animation: 100s scroll-bottom infinite linear;
    -moz-animation: 100s scroll-bottom infinite linear;
    -o-animation: 100s scroll-bottom infinite linear;
    -ms-animation: 100s scroll-bottom infinite linear;
    animation: 100s scroll-bottom infinite linear
}

.bg-anim-l {
    -webkit-animation: 400s scroll-left infinite linear;
    -moz-animation: 400s scroll-left infinite linear;
    -o-animation: 400s scroll-left infinite linear;
    -ms-animation: 400s scroll-left infinite linear;
    animation: 400s scroll-left infinite linear
}

.bg-anim-lf {
    -webkit-animation: 100s scroll-left infinite linear;
    -moz-animation: 100s scroll-left infinite linear;
    -o-animation: 100s scroll-left infinite linear;
    -ms-animation: 100s scroll-left infinite linear;
    animation: 100s scroll-left infinite linear
}

.bg-anim-r {
    -webkit-animation: 400s scroll-right infinite linear;
    -moz-animation: 400s scroll-right infinite linear;
    -o-animation: 400s scroll-right infinite linear;
    -ms-animation: 400s scroll-right infinite linear;
    animation: 400s scroll-right infinite linear
}

.bg-anim-rf {
    -webkit-animation: 100s scroll-right infinite linear;
    -moz-animation: 100s scroll-right infinite linear;
    -o-animation: 100s scroll-right infinite linear;
    -ms-animation: 100s scroll-right infinite linear;
    animation: 100s scroll-right infinite linear
}

@keyframes scroll-left {
    100% {
        background-position: -6500px 0
    }
}

@keyframes scroll-right {
    100% {
        background-position: 6500px 0
    }
}

@keyframes scroll-top {
    100% {
        background-position: 0 -6500px
    }
}

@keyframes scroll-bottom {
    100% {
        background-position: 0 6500px
    }
}


.promoHeader {
    /* font-size: 15px */
}

.promoDesc {
    margin-top: 3% !important;
    font-size: 22px !important;
}

@media (max-width: 768px) {
    .promoHeader {
        /* margin-top: 2% !important; */
        font-size: 20px !important
    }

    .promoDesc {
        /* margin-top: 3% !important;
        font-size: 12px !important; */
        margin-top: 0% !important;
        font-size: 15px !important;
    }
}