:root {
  --cp-1: #4cae4e;
  --cp1-rgb: 76, 174, 78;
  --cp-2: #2196f3;
  --cp2-rgb: 33, 150, 243;
  --cp-3: #212121;
  --cp3-rgb: 33, 33, 33;
  --cp-4: #263238;
  --cp4-rgb: 38, 50, 56;
  --cp-1a: #4cae4e;
  --cp-1b: #6caf6d;
  --cp-1c: #99b699;
  --cp-2a: #2196f3;
  --cp-2b: #00838f;
  --cp-2c: #004d40;
  --cp-3a: #212121;
  --cp-3b: #424242;
  --cp-3c: #757575;
  --cp-4a: #263238;
  --cp-4b: #455a64;
  --cp-4c: #37474f;
  --cp-focus: #e7dc3e;
  --mainheader-height: 80px;
  --extendedHeader-height: 30px;
  --logo-height: 30%;
  --banner-height: 35vh;
  --banner-max-height: 200px;
  --banner-min-height: 150px;
  --blue: #007bff;
  --indigo: #6610f2;
  --purple: #6f42c1;
  --pink: #e83e8c;
  --red: #dc3545;
  --orange: #fd7e14;
  --yellow: #ffc107;
  --green: #28a745;
  --teal: #20c997;
  --cyan: #17a2b8;
  --white: #fff;
  --gray: #6c757d;
  --gray-dark: #343a40;
  --primary: #007bff;
  --secondary: #6c757d;
  --success: #28a745;
  --info: #17a2b8;
  --warning: #ffc107;
  --danger: #dc3545;
  --light: #f8f9fa;
  --dark: #343a40;
  --breakpoint-xs: 0;
  --breakpoint-sm: 576px;
  --breakpoint-md: 986px;
  --breakpoint-lg: 992px;
  --breakpoint-xl: 1200px;
  --font-family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI",
    Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif,
    "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas,
    "Liberation Mono", "Courier New", monospace;
}

body {
  font-family: "Roboto", sans-serif !important;
  -webkit-font-smoothing: antialiased !important;
}

h3 {
  font-size: 1.53125rem !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  margin-bottom: 0.5rem !important;
  font-weight: 500 !important;
  line-height: 1.2 !important;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #5c6873;
  text-align: center;
  white-space: nowrap;
  background-color: #f0f3f5;
  border: 1px solid #e4e7ea;
  border-radius: 0.25rem;
}

.default-color {
  background-color: #4cae4e !important;
}
.navbar.navbar-dark .breadcrumb .nav-item .nav-link,
.navbar.navbar-dark .navbar-nav .nav-item .nav-link {
  color: #fff;
  -webkit-transition: 0.35s;
  transition: 0.35s;
}

.sliderText {
  display: inline-block;
  width: auto;
  font-weight: 599;
  font-size: 60px;
  color: white;
  line-height: 38px;
  text-shadow: 0 0 20px rgba(0, 0, 0, 0.95);
}

.center {
  padding: 130px 0;
  text-align: center;
}

#root {
  background-color: #263238;
}

.noPad > div {
  padding: 10px !important;
}

.noPad2 > div {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.noPad3 > div {
  padding: 0px !important;
}

.noPad4 > div.ant-modal-content > div.ant-modal-body {
  padding: 0px !important;
}

.noPad5 > div.ant-modal-content > div.ant-modal-body {
  padding: 5px !important;
}

.higlightGreen {
  color: white;
  padding: 5px;
  cursor: pointer;
  background-color: #424242;
}

.higlightGreen:hover {
  background-color: #4cae4e;
  cursor: pointer;
}

.higlightGray :hover {
  background-color: #424242 !important;
  cursor: pointer;
}

.table-row-light {
  background-color: #455a64;
  color: white;
}
.table-row-dark {
  background-color: #455a64;
  color: white;
}

.ant-table-small {
  border: 0px solid #e8e8e8 !important;
}

.ant-table-header-column {
  width: 100%;
}

.ant-table-thead {
  background-color: #37474f;
  color: #468df4;
}

.tableitem :hover {
  background-color: #4cae4e !important;
  cursor: pointer;
}

.tableitemPrice {
  float: right;
  margin-right: 5px;
  color: #4cae4e;
}

.tableitemPrice :hover {
  color: #ffffff;
}

.tableitem:hover .tableitemPrice {
  color: #ffffff;
}

.active > center > strong {
  color: white !important;
  text-decoration: white !important;
}

.active {
  border-bottom: 3px solid #4cae4e;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  height: 100%;
}

.menuItem:hover {
  border-bottom: 3px solid white !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  height: 100%;
}

.menuItem2 {
  border-bottom: 3px solid white !important;
  background-color: #4cae4e !important;
  height: 100%;
}

.activeI {
  color: #4cae4e !important;
  color: var(--cp-focus) !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0px -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.dropdown-default {
  right: 0px !important;
}

.dropdown-default2 {
  right: 0px !important;
  position: absolute;
  min-width: 7em !important;
}

.ant-radio-button-wrapper {
  width: 20%;
  background-color: #455a64 !important;
  color: white !important;
  border-radius: 3px;
  margin: 5px;
  border-top: 1px solid #37474f !important;
  border-bottom: 1px solid #37474f !important;
  border-left: 1px solid #37474f !important;
  border-right: 1px solid #37474f !important;
  border-color: #37474f !important;
}

.ant-radio-button-wrapper-checked {
  border-color: #4cae4e !important;
  box-shadow: -1px 0 0 0 #4cae4e !important;
  color: #4cae4e !important;
}

.ant-radio-button-wrapper-disabled {
  background-color: #263238 !important;
}

.ant-table-row {
  border: 2px solid #37474f !important;
}

tr {
  border: 2px solid #37474f !important;
}
td {
  border-top: 2px solid #37474f !important;
  border-bottom: 2px solid #37474f !important;
}

.ant-table-tbody > tr.ant-table-row-level-1:hover > td {
  background: #455a64 !important;
}

.ant-table-tbody > tr:hover {
  background: #455a64 !important;
}

.ant-table-tbody > tr:hover > td {
  background: #455a64 !important;
}

.mobTable {
  display: none !important;
}

.middleView {
  max-height: 1100 !important;
}
.fabb {
  display: none !important;
  box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18), 0 4px 15px 0 rgba(0, 0, 0, 0.15);
}
.ant-drawer-body {
  padding: 0px !important;
}

.ant-input-prefix > input {
  border-radius: 0px !important;
}

.mainLogoContainer {
  padding-left: 10px !important;
}

.betHistoryItem {
  cursor: pointer;
}

.betHistoryItem:hover {
  cursor: pointer;
  background-color: #546e7a80 !important;
}

.pageNavigation {
  color: white;
  vertical-align: middle !important;
  cursor: pointer;
}
.pageNavigation:hover {
  color: #4cae4e !important;
}

.btn-block {
  display: block;
  width: 100%;
}

.btn-primary {
  color: #fff !important;
  background-color: #20a8d8 !important;
  border-color: #20a8d8 !important;
}

.btn-success {
  color: #fff !important;
  background-color: #4dbd74 !important;
  border-color: #4dbd74 !important;
}

.btn {
  display: inline-block;
  font-weight: 400;
  color: #23282c;
  text-align: center;
  vertical-align: middle;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.ant-collapse-item ant-collapse-no-arrow {
  border-bottom: 1px solid gray !important;
}

.ant-collapse-borderless > .ant-collapse-item {
  border-bottom: 1px solid gray !important;
}

.ant-tabs-tab {
  border-radius: 0px !important;
  border: 0px !important;
  border-bottom: 1px solid white !important;
  color: white !important;
  background-color: #37474f !important;
}

.ant-tabs-bar {
  margin: 0 0 0px 0;
}

.ant-tabs-tab-active {
  color: white !important;
  background-color: #4cae4e !important;
  border-radius: 0px !important;
  border: 0px !important;
  border-bottom: 1px solid white !important;
}

.ant-input-search-icon {
  color: white !important;
}

.ant-input-search > input {
  color: white !important;
  background-color: #424242d1 !important;
  border: 0px;
  border-bottom: 0.1px solid white;
  border-radius: 0px;
}

.ant-collapse-content-box {
  background: #00000017 !important;
  padding: 0px !important;
}

.ant-collapse-header {
  padding: 7px 16px !important;
}

.ant-drawer-left
  > .ant-drawer-content-wrapper
  > .ant-drawer-content
  > .ant-drawer-wrapper-body {
  background-color: white !important;
}

.ant-drawer-header {
  background: #000 !important;
  border: 0px !important;
}

.anticon-loading {
  margin-right: 10px !important;
}

.ant-pagination-item {
  background-color: #37474f !important;
}

.ant-pagination-item a {
  color: white !important;
}

.leftNavSection {
  padding-top: 10px !important;
  padding-left: 10px !important;
}

.middleView {
  padding-top: 10px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
}

.rightNavSection {
  padding-top: 10px !important;
  padding-right: 10px !important;
}

.breadcrumbico {
  display: none !important;
}

@media (max-width: 985px) {
  .middleView {
    height: 700 !important;
  }
  .mobTable {
    display: initial !important;
  }
  .fullTable {
    display: none !important;
  }
  .sliderText {
    font-size: 20px;
  }
  .headerRight {
    display: none !important;
  }
  .breadcrumbico {
    display: block !important;
  }
  .floatRightimg {
    float: right !important;
  }
  .fabb {
    display: initial !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.8), 0 4px 15px 0 rgba(0, 0, 0, 0.8);
  }
  .fixHeight {
    height: 47px;
  }
  .mainLogo {
    height: 55px;
  }
  .mainLogoContainer {
    padding-left: 0px !important;
  }
}

@media (max-width: 560px) {
  /* 426 */
  .middleView {
    height: 700 !important;
  }
  .mobTable {
    display: initial !important;
  }
  .fullTable {
    display: none !important;
  }
  .sliderText {
    font-size: 20px;
  }
  .headerRight {
    display: none !important;
  }
  .breadcrumbico {
    display: block !important;
  }
  .floatRightimg {
    float: right !important;
  }
  .fabb {
    display: initial !important;
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.8), 0 4px 15px 0 rgba(0, 0, 0, 0.8);
  }
  .fixHeight {
    height: 47px;
  }
  .mainLogo {
    height: 55px;
  }
  .mainLogoContainer {
    padding-left: 0px !important;
  }
  .ani {
    height: 150px !important;
  }
  .center {
    padding: 60px 0;
    text-align: center;
  }
}

/* new css import */

.mainHeader {
  color: white !important;
  background-color: var(--cp-1) !important;
  padding: 0 10px 0px 10px !important;
  position: fixed !important;
  display: flex !important;
  height: var(--mainheader-height) !important;
  height: calc(var(--mainheader-height) + 0.3vw) !important;
  width: 100% !important;
  z-index: 1 !important;
}

.extendedHeader {
  color: white !important;
  background-color: var(--cp-3b) !important;
  padding: 0 calc(3px + 0.5vw) !important;
  position: fixed !important;
  display: flex !important;
  line-height: calc(var(--extendedHeader-height) + 0.3vw) !important;
  height: calc(var(--extendedHeader-height) + 0.3vw) !important;
  min-height: var(--extendedHeader-height) !important;
  margin-top: var(--mainheader-height) !important;
  width: 100% !important;
  z-index: 1 !important;
}

.mainHeader .left-nav {
  flex: 0 0 auto;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: center !important;
  -ms-flex-align: center;
  align-items: center;
  line-height: 100% !important;
  vertical-align: middle !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
  margin: auto 10px auto auto !important;
  text-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.02),
    0 3px 1px -2px rgba(0, 0, 0, 0.1) !important;
}

.mainHeader .left-nav .anticon {
  line-height: 100% !important;
  display: inline !important;
  vertical-align: middle !important;
  font-size: 28px !important;
  font-weight: bold !important;
  margin: auto 0 auto 0 !important;
  padding-top: unset !important;
  padding-left: unset !important;
}

.mainHeader .mainLogoContainer {
  flex: 1 1 auto;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center !important;
  -ms-flex-align: center;
  align-items: center;
  font-weight: lighter !important;
  font-size: calc(0.4rem + 0.5vw) !important;
  color: white !important;
  height: 100% !important;
  min-height: 100% !important;
  padding: 0 !important;
}

.mainHeader .right-nav {
  flex: 0 0 auto;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end !important;
  -ms-flex-align: end;
  align-items: end;
}

.mainHeader .mainLogoContainer img.mainLogo {
  padding: 0px !important;
  height: inherit !important;
  min-height: var(--logo-height) !important;
  max-height: calc(var(--logo-height) + 50%) !important;
  width: auto !important;
  position: absolute !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto !important;
}

.mainHeader > .right-nav > .ant-row {
  font-weight: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  flex: 1 1 auto !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: auto 0 auto 0 !important;
  display: flex !important;
  min-height: inherit !important;
  width: 100% !important;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.mainHeader .right-nav .header-item {
  font-weight: inherit !important;
  font-size: inherit !important;
  color: inherit !important;
  flex: 1 1 auto !important;
  top: 0 !important;
  bottom: 0 !important;
  margin: 3.5px 0 3.5px 0 !important;
  min-height: inherit !important;
  padding: auto 10px auto 0 !important;
  padding-left: 0 !important;
  padding-right: 10px !important;
  width: max-content !important;
  max-width: 240px !important;
  min-width: 135px !important;
  display: none;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.mainHeader .right-nav .header-item.header-item-btn {
  flex: 0 0 auto !important;
  min-width: max-content !important;
}

.mainHeader .right-nav .header-item.header-item-lang {
  flex: 0 0 auto !important;
  min-width: max-content !important;
  margin-left: 20px !important;
}

.headerRight .header-form-holder {
  width: 100% !important;
  min-width: 100% !important;
}

.mainHeader .ant-input-affix-wrapper {
  min-width: 100% !important;
}

.mainHeader .ant-input-affix-wrapper input.ant-input {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  margin: 0;
  font-weight: inherit !important;
  font-size: inherit !important;
  color: var(--cp-1) !important;
  list-style: none;
  position: relative;
  display: inline-block;
  width: 100%;
  height: 100%;
  line-height: 100%;
  padding: 5px 3px 5px 35px !important;
  background-color: #fff;
  background-image: none;
  border: none !important;
  border-radius: 0px !important;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.04), 0 1px 5px 0 rgba(0, 0, 0, 0.02),
    0 3px 1px -2px rgba(0, 0, 0, 0.1) !important;
}

.mainHeader .ant-input-affix-wrapper .ant-input-prefix,
.mainHeader .ant-input-affix-wrapper .ant-input-suffix {
  position: absolute;
  top: 50%;
  z-index: 2;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  line-height: 20px;
  left: 0;
  height: 100% !important;
  font-weight: bold !important;
  font-size: large !important;
  color: var(--cp-2) !important;
  width: auto;
  min-width: 25px !important;
  max-width: 30px !important;
  padding-left: 5px !important;
  padding-right: 5px !important;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-right: 0.003rem solid rgba(var(--cp2-rgb), 0.2);
}

.mainHeader .ant-input-affix-wrapper .ant-input-prefix > .anticon,
.mainHeader .ant-input-affix-wrapper .ant-input-suffix > .anticon {
  color: inherit !important;
  font-weight: inherit !important;
  font-size: inherit !important;
}

.mainHeader .header-item-btn button.ant-btn.LoginButton {
  margin: 0;
  font-weight: bold !important;
  font-size: inherit !important;
  color: inherit !important;
  line-height: 100% !important;
  line-height: 100%;
  height: auto !important;
  width: 100% !important;
  padding: 7px 15px !important;
  text-align: center;
  border: none !important;
  border-radius: 0px !important;
  background-color: var(--cp-2) !important;
  box-shadow: 0 4px 5px 0 rgba(0, 0, 0, 0.14), 0 1px 10px 0 rgba(0, 0, 0, 0.12),
    0 2px 4px -1px rgba(0, 0, 0, 0.3) !important;
}

.header-link-text {
  width: 100% !important;
  height: 28px !important;
  min-height: 28px !important;
  max-height: 28px !important;
  line-height: 100%;
  padding: 5px 2px 5px 15px !important;
  min-width: 100% !important;
}

.headerLink {
  /* padding: 0 calc(3px + 0.5vw) 0 calc(3px + 0.5vw) !important; */
  display: block !important;
  font-weight: bold !important;
  font-size: inherit !important;
  height: 100% !important;
  min-height: 100% !important;
  color: rgba(255, 255, 255, 0.85) !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0px -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
}

.extendedHeader .left-nav {
  flex: 1 1 auto;
  min-width: 10%;
  max-width: 90%;
  display: flex;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
}

.extendedHeader .right-nav {
  flex: 1 1 auto;
  min-width: 30%;
  display: flex;
  -webkit-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.extendedHeader .menuItem {
  cursor: pointer !important;
  padding: 0 1vw 0 1vw !important;
  padding: 0 calc(3px + 1vw) 0 calc(3px + 1vw) !important;
  display: none;
  font-size: calc(0.4rem + 0.7vw) !important;
  font-weight: bold !important;
  color: rgba(255, 255, 255, 0.75) !important;
  height: 100% !important;
  text-shadow: 2px 8px 6px rgba(var(--cp4-rgb), 0.5),
    0px -5px 35px rgba(var(--cp3-rgb), 0.2) !important;
  background-color: transparent !important;
  width: max-content !important;
  justify-content: center !important;
  top: 0;
  bottom: 0;
  vertical-align: middle !important;
  margin: auto 0 auto 0 !important;
  border-bottom: 0.2rem solid rgba(var(--cp2-rgb), 0) !important;
}

.header-item.show-any,
.menuItem.show-any,
.header-item.mob-only,
.menuItem.mob-only {
  display: flex !important;
}

.extendedHeader .menuItem .anticon {
  line-height: 100% !important;
  display: inline !important;
  vertical-align: middle !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  margin: auto 10px auto 0 !important;
}

.extendedHeader .menuItem.menuItemButton {
  background-color: var(--cp-2) !important;
  margin-right: 0.2rem !important;
}

.extendedHeader .menuItem.active {
  color: white !important;
  background-color: rgba(var(--cp4-rgb), 0.3) !important;
  border-bottom: 0.2rem solid rgba(var(--cp2-rgb), 0.8) !important;
}

.extendedHeader .menuItem.menuItemButton.active,
.extendedHeader .menuItem.menuItemButton:hover {
  background-color: var(--cp-2) !important;
  border-bottom: 0.2rem solid rgba(255, 255, 255, 1) !important;
}

.extendedHeader .menuItem:hover {
  color: white !important;
  background-color: rgba(var(--cp3-rgb), 0.3) !important;
  border-bottom: 0.2rem solid rgba(255, 255, 255, 0.3) !important;
}

.extendedHeader .menuItem.active:hover {
  border-bottom: 0.2rem solid rgba(var(--cp2-rgb), 0.4) !important;
}

.extendedHeader .menuItem-time {
  float: right !important;
  width: max-content !important;
  margin-left: 20px !important;
  font-size: calc(0.4rem + 0.6vw) !important;
  font-weight: lighter !important;
  color: var(--cp-focus) !important;
}

.menuItem-time > span,
.menuItem-time time {
  font-size: inherit !important;
  font-weight: inherit !important;
  color: inherit !important;
  text-shadow: inherit !important;
}

.ant-drawer-content-wrapper,
.ant-drawer-content,
.ant-drawer-body {
  background-color: transparent !important;
}

.ant-drawer-top.ant-drawer-open .ant-drawer-content-wrapper {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  width: 100% !important;
  height: 100% !important;
  padding: 2% !important;
}
.ant-drawer-header {
  position: absolute;
  padding: 10px 24px !important;
  z-index: 99999;
  color: rgba(0, 0, 0, 0.65);
  background: #fff;
  top: 0;
  left: 0;
  right: 0;
  border-bottom: 1px solid #e8e8e8;
  border-radius: 4px 4px 0 0;
  background-color: #000 !important;
  height: 40px;
}
.ant-drawer-header > .ant-drawer-title > span {
  font-size: 18px;
  font-weight: bolder;
  margin-left: 10px;
}
.ant-drawer-body > div {
  padding-top: 5px !important;
}
.ant-drawer-body > div > br {
  display: none !important;
}
.ant-drawer-body {
  padding: 0px !important;
  margin-top: auto;
  font-size: 14px;
  line-height: 1.5;
  word-wrap: break-word;
}

.filterBox {
  background-color: #37474f;
  color: white;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
}

/* .filterItem {
  border: 1px solid white;
} */

.filterItem:hover {
  background-color: #4cae4e;
  /* border-bottom:  1px solid white; */
}

.ant-collapse-content-box {
  background: #00000017 !important;
}

.ant-drawer-wrapper-body {
  background-color: #000000d9 !important;
}

.ant-drawer-title {
  color: #4cae4e !important;
}

.slipTitle {
  cursor: pointer;
}

.slipTitle:hover {
  color: #4cae4e !important;
}

.ant-collapse-borderless {
  background-color: transparent !important;
}